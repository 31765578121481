import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { extractData } from '../../../Consumer';
import OrderFilter from './OrderFilter';
import LoadingBar from '../../common/LoadingBar';
import SubNav from '../../common/SubNav';
import OrderHistoryTable from './OrderHistoryTable';
import { encodeStringForURL } from '../../../Utilities';

import { companyActions } from '../../../actions/companyActions';

class CompanygetProcessingOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            totalCount: 0,
            pageIndex: 1,
            pageCount: 0,
            orderHistories: [],
            activeKey: 'Orders in Process',
            orderType: 'processing',
            filter: {
                orderNumber: "",
                dateFrom: undefined,
                dateTo: undefined,
                sortBy: ""
            }
        };
    }

    componentDidMount() {
        const { pageIndex } = this.state
        this.getProcessingOrders(this.props.company.id, pageIndex);
    }

    getProcessingOrders = (id, pageIndex) => {
        const { orderType, filter } = this.state
        this.props.getOrderHistories(id, filter, orderType, pageIndex)
            .then((result) => {
                if (!result.error) {
                    const data = extractData(result);
                    this.setState({
                        orderHistories: data.orders,
                        totalCount: data.total,
                        pageCount: data.pageCount,
                        loading: false
                    });
                }
                else {
                    this.setState({ loading: false });
                }
            })
            .catch((error) => { console.log(error) });
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    setLoading = (loading) => {
        this.setState({ loading: loading });
    }

    onClear = () => {
        const filter = {
            orderNumber: "",
            dateFrom: undefined,
            dateTo: undefined,
            sortBy: ""
        };

        this.setState({
            orderHistories: [],
            filter: filter
        }, this.getProcessingOrders(this.props.company.id, 1));
    }

    onSearch = (e) => {
        e.preventDefault();
        const { pageIndex } = this.state;
        this.setState({ orderHistories: [], loading: true }, this.getProcessingOrders(this.props.company.id, pageIndex));
    }

    onNext = () => {
        const { pageIndex, pageCount } = this.state;
        const newIndex = parseInt(pageIndex) + 1;

        if (pageIndex < pageCount) {
            this.setState({ pageIndex: newIndex, loading: true }, this.getProcessingOrders(this.props.company.id, newIndex));
        }
    }

    onPrevious = () => {
        const { pageIndex } = this.state;
        const newIndex = parseInt(pageIndex) - 1;

        if (pageIndex > 1) {
            this.setState({ pageIndex: newIndex, loading: true }, this.getProcessingOrders(this.props.company.id, newIndex));
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.getProcessingOrders(this.props.company.id, value));
    }

    renderOrderRows = () => {
        const { activeKey, orderHistories, pageIndex, pageCount } = this.state;
        const companyNameEncoded = encodeStringForURL(this.props.company.name.toLowerCase());

        const OrderHistoryBreadcrumbs = [
            { text: "Orders in Process", link: `/companies/${companyNameEncoded}/orderhistory/processing` },
            { text: "Completed Orders", link: `/companies/${companyNameEncoded}/orderhistory/completed` },
            { text: "Failed Orders", link: `/companies/${companyNameEncoded}/orderhistory/failed` }
        ];

        return (
            <Container fluid>
                <Link className="link" to={'/companies'}>
                    Companies
                </Link> <FontAwesomeIcon icon={faAngleRight} /> <Link className="link" to={`/companies/${companyNameEncoded}`}>
                    {this.props.company.name}</Link> <FontAwesomeIcon icon={faAngleRight} /> Orders

                    <h2 className='mt-4'>Orders</h2>
                <SubNav breadcrumbs={OrderHistoryBreadcrumbs} activeKey={activeKey} />

                <Row className="my-3">
                    <Col>
                        <OrderFilter
                            onSearch={this.onSearch}
                            onFilterChange={this.onFilterChange}
                            onClear={this.onClear}
                            {...this.state.filter}
                            onLoading={this.setLoading} />
                    </Col>
                </Row>

                <OrderHistoryTable
                    orderHistories={orderHistories}
                    onNext={this.onNext}
                    onPrevious={this.onPrevious}
                    onPageSelect={this.onPageSelect}
                    pageIndex={pageIndex}
                    pageCount={pageCount} />
            </Container>
        );
    }

    render() {
        return (
            <Container fluid>
                {this.state.loading ? <LoadingBar /> : this.renderOrderRows()}
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.verifiedCompany
    }
}

const mapDispatchToProps = {
    getOrderHistories: companyActions.getOrderHistories
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanygetProcessingOrders)