import React, { Component } from 'react';
import { Button, Card, Col, Container, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import Stepper from './Stepper';

import { companyActions } from '../../../actions/companyActions';
import { encodeStringForURL } from '../../../Utilities';

class CompanyVerificationStepTwo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            codeConfirmed: false,
            codeConfirmationError: false,
            codeFail: false,
            codeRecieved: false,
            codeSuccess: false,
            step: 2
        }
    }

    sendCode = () => {
        const email = this.props.user.email;
        const companyId = this.props.company.id;

        this.props.sendVerificationCode(companyId, email)
            .then((result) => {
                this.props.setVerificationCode(result);
            })
            .then(() => {
                this.setState({
                    codeSuccess: true,
                    codeFail: false
                });
            })
            .catch(() => {
                this.setState({
                    codeFail: true,
                    codeSuccess: false
                });
            });
    }

    setRecieved = () => {
        let state = !this.state.codeRecieved;
        this.setState({ codeRecieved: state, codeConfirmed: state });
    }

    next = () => {
        if (this.state.codeRecieved) {
            this.props.setCurrentVerificationStep(this.state.step + 1);
            this.props.history.push('./step3');
        } else {
            this.setState({ confirmationError: true });
        }
    }

    previous = () => {
        this.props.setCurrentVerificationStep(this.state.step - 1);
        this.props.history.push('./step2');
    }

    render() {
        const { codeConfirmed, confirmationError, codeSent, codeSuccess, step } = this.state;

        const sendCodeSuccess = () => {
            return <React.Fragment>
                <Card className="card-success mt-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />Success</Card.Title>
                    <Card.Body>
                        You have sent a four digit code successfully
                    </Card.Body>
                </Card>
            </React.Fragment>
        }

        const codeConfirmationError = () => {
            return <React.Fragment>
                <Card className="card-danger mt-3 mb-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>
                        You have not confirmed that the customer recieved their four digit code
                    </Card.Body>
                </Card>
            </React.Fragment>
        }

        const steps = ['Send Code', 'Confirm Code Was Recieved', 'Enter Code'];

        return (
            this.props.currentVerificationStep !== step ? <Redirect to={`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/verify/step${this.props.currentVerificationStep}`} /> :
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h2 className="block mb-4">Company Profile</h2>
                            <Stepper steps={steps} activeStep={1} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col md={5}>
                            <h5 className="integration-section-header">Confirm Code was Recieved</h5>

                            {confirmationError && codeConfirmationError()}

                            <div className="mt-4">
                                <span className="d-block mb-2">
                                    Ask the customer have they received the email containing the four digit code.
                            </span>
                                <span className="d-block-inline mr-2">Confirm customer received code</span>
                                <FormGroup className="custom-control custom-checkbox d-inline">
                                    <input id="cb" className="custom-control-input" type="checkbox" onChange={this.setRecieved} checked={codeConfirmed} />
                                    <FormLabel className="custom-control-label" htmlFor="cb" />
                                </FormGroup>
                            </div>

                            <div className="mt-5">
                                <strong>Customer not received their code?</strong>
                                <span className="d-block mt-1">Ask the customer to do the following:</span>
                                <span className="d-block mt-2">
                                    1. Refresh their emails
                                </span>
                                <span className="d-block mt-2">
                                    2. Check their spam/junk folders
                                </span>
                            </div>
                            <div className="mt-5">
                                <strong>Customer still not received their code?</strong>
                                <span className="d-block mt-1">Send the customer a new code</span>
                            </div>

                            {codeSuccess && sendCodeSuccess()}

                            {(!codeSent && !codeSuccess) && <Button className="link-button mt-1" variant="secondary" onClick={this.sendCode}>
                                Send a new code
                        </Button>}
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col md={5}>
                            <Button className="float-left" variant="secondary" onClick={this.previous}>Back</Button>
                            <Button className="btn btn-primary float-right" onClick={this.next}>Continue to next step</Button>
                            <Link to='/companies' className="link-button" onClick={this.previous}>
                                <Button variant="link" className="float-right">Cancel</Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.companyReducer.companyUser,
        company: state.companyReducer.company,
        currentVerificationStep: state.companyReducer.currentVerificationStep
    }
}

const mapDispatchToProps = {
    setCurrentVerificationStep: companyActions.setCurrentVerificationStep,
    sendVerificationCode: companyActions.sendVerificationCode,
    setVerificationCode: companyActions.setVerificationCode
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyVerificationStepTwo)