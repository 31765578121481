import React from 'react';

import './Slider.scss';

const Slider = ({ id, name, label, checked, onChange }) =>
    <div className="switch-container py-2">
        <label className="switch">
            <input id={id} name={name} type="checkbox" checked={checked} onChange={onChange} />
            <span className="slider round"></span>
        </label>
        <label className="switch-label mt-1 ml-2">{label}</label>
    </div>

export default Slider;