// Components/Company 
export const COMPANY_GET_ALL = 'COMPANY_GET_ALL';
export const COMPANY_GET_ALL_SUCCESS = 'COMPANY_GET_ALL_SUCCESS';
export const COMPANY_GET_ALL_FAILURE = 'COMPANY_GET_ALL_FAILURE';

export const COMPANY_GET_ALL_WITH_ACCESS_STATUS = 'COMPANY_GET_ALL_WITH_ACCESS_STATUS';
export const COMPANY_GET_ALL_WITH_ACCESS_STATUS_SUCCESS = 'COMPANY_GET_ALL_WITH_ACCESS_STATUS_SUCCESS';
export const COMPANY_GET_ALL_WITH_ACCESS_STATUS_FAILURE = 'COMPANY_GET_ALL_WITH_ACCESS_STATUS_FAILURE';

export const COMPANY_GET_ACCESS = 'COMPANY_GET_ACCESS';
export const COMPANY_GET_ACCESS_SUCCESS = 'COMPANY_GET_ACCESS_SUCCESS';
export const COMPANY_GET_ACCESS_FAILURE = 'COMPANY_GET_ACCESS_FAILURE';

export const COMPANY_GET_TEMPORARY_LOGIN = 'COMPANY_GET_TEMPORARY_LOGIN';
export const COMPANY_GET_TEMPORARY_LOGIN_SUCCESS = 'COMPANY_GET_TEMPORARY_LOGIN_SUCCESS';
export const COMPANY_GET_TEMPORARY_LOGIN_FAILURE = 'COMPANY_GET_TEMPORARY_LOGIN_FAILURE';

export const COMPANY_GET_BY_ID = 'COMPANY_GET_BY_ID';
export const COMPANY_GET_BY_ID_SUCCESS = 'COMPANY_GET_BY_ID_SUCCESS';
export const COMPANY_GET_BY_ID_FAILURE = 'COMPANY_GET_BY_ID_FAILURE';

export const COMPANY_GET_BILLING_ADDRESS = 'COMPANY_GET_BILLING_ADDRESS';
export const COMPANY_GET_BILLING_ADDRESS_SUCCESS = 'COMPANY_GET_BILLING_ADDRESS_SUCCESS';
export const COMPANY_GET_BILLING_ADDRESS_FAILURE = 'COMPANY_GET_BILLING_ADDRESS_FAILURE';

export const COMPANY_GET_CARD_DETAILS = 'COMPANY_GET_CARD_DETAILS';
export const COMPANY_GET_CARD_DETAILS_SUCCESS = 'COMPANY_GET_CARD_DETAILS_SUCCESS';
export const COMPANY_GET_CARD_DETAILS_FAILURE = 'COMPANY_GET_CARD_DETAILS_FAILURE';

export const COMPANY_GET_DIRECT_DEBIT = 'COMPANY_GET_CARD_DETAILS';
export const COMPANY_GET_DIRECT_DEBIT_SUCCESS = 'COMPANY_GET_CARD_DETAILS_SUCCESS';
export const COMPANY_GET_DIRECT_DEBIT_FAILURE = 'COMPANY_GET_CARD_DETAILS_FAILURE';

export const COMPANY_UPDATE_DIRECT_DEBIT = 'COMPANY_GET_CARD_DETAILS';
export const COMPANY_UPDATE_DIRECT_DEBIT_SUCCESS = 'COMPANY_GET_CARD_DETAILS_SUCCESS';
export const COMPANY_UPDATE_DIRECT_DEBIT_FAILURE = 'COMPANY_GET_CARD_DETAILS_FAILURE';

export const COMPANY_SEND_CODE = 'COMPANY_SEND_CODE';
export const COMPANY_SEND_CODE_SUCCESS = 'COMPANY_SEND_CODE_SUCCESS';
export const COMPANY_SEND_CODE_FAILURE = 'COMPANY_SEND_CODE_FAILURE';

export const COMPANY_VERIFY_CODE = 'COMPANY_VERIFY_CODE';
export const COMPANY_VERIFY_CODE_SUCCESS = 'COMPANY_VERIFY_CODE_SUCCESS';
export const COMPANY_VERIFY_CODE_FAILURE = 'COMPANY_VERIFY_CODE_FAILURE';

export const COMPANY_UPDATE_NAME = 'COMPANY_UPDATE_NAME';
export const COMPANY_UPDATE_NAME_SUCCESS = 'COMPANY_UPDATE_NAME_SUCCESS';
export const COMPANY_UPDATE_NAME_FAILURE = 'COMPANY_UPDATE_NAME_FAILURE';

export const COMPANY_UPDATE_EMAIL = 'COMPANY_UPDATE_EMAIL';
export const COMPANY_UPDATE_EMAIL_SUCCESS = 'COMPANY_UPDATE_EMAIL_SUCCESS';
export const COMPANY_UPDATE_EMAIL_FAILURE = 'COMPANY_UPDATE_EMAIL_FAILURE';

export const COMPANY_GET_PROCESSING_ORDERS = 'COMPANY_GET_PROCESSING_ORDERS';
export const COMPANY_GET_PROCESSING_ORDERS_SUCCESS = 'COMPANY_GET_PROCESSING_ORDERS_SUCCESS';
export const COMPANY_GET_PROCESSING_ORDERS_FAILURE = 'COMPANY_GET_PROCESSING_ORDERS_FAILURE';

export const COMPANY_GET_PROCESSED_ORDERS = 'COMPANY_GET_PROCESSED_ORDERS';
export const COMPANY_GET_PROCESSED_ORDERS_SUCCESS = 'COMPANY_GET_PROCESSED_ORDERS_SUCCESS';
export const COMPANY_GET_PROCESSED_ORDERS_FAILURE = 'COMPANY_GET_PROCESSED_ORDERS_FAILURE';

export const COMPANY_GET_FAILED_ORDERS = 'COMPANY_GET_FAILED_ORDERS';
export const COMPANY_GET_FAILED_ORDERS_SUCCESS = 'COMPANY_GET_FAILED_ORDERS_SUCCESS';
export const COMPANY_GET_FAILED_ORDERS_FAILURE = 'COMPANY_GET_FAILED_ORDERS_FAILURE';

export const COMPANY_GET_ORDER_COSTS = 'COMPANY_GET_ORDER_COSTS';
export const COMPANY_GET_ORDER_COSTS_SUCCESS = 'COMPANY_GET_ORDER_COSTS_SUCCESS';
export const COMPANY_GET_ORDER_COSTS_FAILURE = 'COMPANY_GET_ORDER_COSTS_FAILURE';

export const COMPANY_GET_TRACKING_URL_ROOT = 'COMPANY_GET_TRACKING_URL_ROOT';
export const COMPANY_GET_TRACKING_URL_ROOT_SUCCESS = 'COMPANY_GET_TRACKING_URL_ROOT_SUCCESS';
export const COMPANY_GET_TRACKING_URL_ROOT_FAILURE = 'COMPANY_GET_TRACKING_URL_ROOT_FAILURE';

export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

export const COMPANY_SET_CODE = 'COMPANY_SET_CODE';

export const COMPANY_SET_COMPANY = 'COMPANY_SET_COMPANY';
export const COMPANY_RESET_COMPANY = 'COMPANY_RESET_COMPANY';

export const COMPANY_SET_USER = 'COMPANY_SET_USER';

export const COMPANY_SET_CURRENT_VERIFICATION_STEP = 'COMPANY_SET_CURRENT_VERIFICATION_STEP';

export const SET_VERIFIED_COMPANY_DETAILS = 'SET_VERIFIED_COMPANY_DETAILS';

export const SET_VERIFIED_COMPANY_TIMER = 'SET_VERIFIED_COMPANY_TIMER';
export const RESET_VERIFIED_COMPANY_TIMER = 'RESET_VERIFIED_COMPANY_TIMER';

export const CREATE_ADHOC_CHARGE = 'CREATE_ADHOC_CHARGE';
export const CREATE_ADHOC_CHARGE_SUCCESS = 'CREATE_ADHOC_CHARGE_SUCCESS';
export const CREATE_ADHOC_CHARGE_FAILURE = 'CREATE_ADHOC_CHARGE_FAILURE';

export const CREATE_CREDIT_NOTE = 'CREATE_CREDIT_NOTE';
export const CREATE_CREDIT_NOTE_SUCCESS = 'CREATE_CREDIT_NOTE_SUCCESS';
export const CREATE_CREDIT_NOTE_FAILURE = 'CREATE_CREDIT_NOTE_FAILURE';

export const GET_CREDIT_NOTES = 'GET_CREDIT_NOTES';
export const GET_CREDIT_NOTES_SUCCESS = 'GET_CREDIT_NOTES_SUCCESS';
export const GET_CREDIT_NOTES_FAILURE = 'GET_CREDIT_NOTES_FAILURE';

export const GET_CREDIT_NOTE_TOTAL = 'GET_CREDIT_NOTE_TOTAL';
export const GET_CREDIT_NOTE_TOTAL_SUCCESS = 'GET_CREDIT_NOTE_TOTAL_SUCCESS';
export const GET_CREDIT_NOTE_TOTAL_FAILURE = 'GET_CREDIT_NOTE_TOTAL_FAILURE';

export const GET_LAST_CREDIT_ISSUE_DATE = 'GET_LAST_CREDIT_ISSUE_DATE';
export const GET_LAST_CREDIT_ISSUE_DATE_SUCCESS = 'GET_LAST_CREDIT_ISSUE_DATE_SUCCESS';
export const GET_LAST_CREDIT_ISSUE_DATE_FAILURE = 'GET_LAST_CREDIT_ISSUE_DATE_FAILURE';

export const UPDATE_VAT_EXEMPT = 'UPDATE_VAT_EXEMPT';
export const UPDATE_VAT_EXEMPT_SUCCESS = 'UPDATE_VAT_EXEMPT_SUCCESS';
export const UPDATE_VAT_EXEMPT_FAILURE = 'UPDATE_VAT_EXEMPT_FAILURE';
