import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import './SubNav.scss'

class SubNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: this.props.breadcrumbs,
            activeKey: this.props.activeKey
        }
    }

    render() {
        const { breadcrumbs, activeKey } = this.state

        return breadcrumbs.length ? (
            <Breadcrumb>
                {breadcrumbs.map((breadcrumb, i) => breadcrumb.text === activeKey ?
                    <Breadcrumb.Item key={'link-active-' + i} active>
                        {breadcrumb.text}
                    </Breadcrumb.Item> :
                    <LinkContainer key={'link-' + i} to={breadcrumb.link}>
                        <Breadcrumb.Item>
                            {breadcrumb.text}
                        </Breadcrumb.Item>
                    </LinkContainer>)
                }
            </Breadcrumb>
        ) : null;
    }
}

export default SubNav;