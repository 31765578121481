import { GET_ASYNC } from "../../Consumer";
import Endpoints from "../../components/common/Endpoints";

export const FetchWarehouses = async (companyID, setWarehouses, setErrorMessages) => {
    try {
        const response = await GET_ASYNC(Endpoints.CREDIT.GET.RETRIEVE_WAREHOUSES + companyID);
        if (response?.ok) {
            const result = await response.json();
            if (result.error) {
                setErrorMessages(result.data !== null ? result.data : result.message);
            } else {
                const warehouses = result.data ? result.data : [];
                setWarehouses(warehouses);
            }
        } else {
            setErrorMessages(response.status);
        }
    } catch (error) {
        console.log(error);
        setErrorMessages(["There has been an issue retrieving warehouses."]);
    }
};