import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <Container fluid>
            <h2>Not found!</h2>
            <p>Unable to find the resource you were looking for. Please try again.</p>
            <Link to='/'>
                <Button className="btn btn-primary" >Back to Home</Button>
            </Link>
        </Container>
    );
};

export default NotFound;