import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Container, Image, Row, Table, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import { extractData } from '../../Consumer';
import { toLocalTimeString } from '../../Utilities';
import LoadingBar from '../common/LoadingBar';
import Pagination from '../common/tables/Pagination';
import CompanyFilter from './CompanyFilter';
import Actions from '../common/tables/Actions';
import { checkPermission, encodeStringForURL } from '../../Utilities';

import { companyActions } from '../../actions/companyActions';

class Companies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            companies: [],
            currentCompanyAccess: false,
            filter: {
                name: "",
                pageCount: 1,
                pageIndex: 1,
                totalCount: 0
            }
        }
    }

    componentDidMount() {
        // Reset route blocking for company verification 
        const { history } = this.props;
        history.block(() => { })

        const currentTime = new Date().getTime();
        if (this.props.verifiedCompanyTimeout !== null && currentTime > this.props.verifiedCompanyTimeout) {
            this.props.resetCompany();
        }

        this.props.setCurrentVerificationStep(1);
        this.getCompaniesWithAccess();
    }

    getCompaniesWithAccess() {
        const { filter } = this.state;

        this.props.getAllCompaniesWithAccessData(filter)
            .then((result) => {
                if (result.data != null) {
                    const data = extractData(result);
                    const companies = data.companiesWithAccess.map(data => {
                        let company = Object.assign({}, data.company);
                        company.access = data.securityPreference.access;

                        return company;
                    });

                    this.setState(prevState => ({
                        ...prevState,
                        filter: {
                            ...prevState,
                            ...{ 
                                pageCount: data.pageCount,
                                pageIndex: data.pageIndex,
                                totalCount: data.totalCompanyCount,
                             }
                        },
                        loading: false,
                        companies: companies,
                        totalCount: data.totalCompanyCount
                    }));
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getMainContact = (company) => {
        const mainContactTypeID = "1c60bafa-5a3e-441d-a520-41b7544a7673";
        return company.find(c => c.contact.contactTypeID === mainContactTypeID).contact;
    }

    getActions = (company) => {
        const rowActions = [];
        const accessAccount = company.access ? <Link className="link-button" to={{ pathname: `/companies/${encodeStringForURL(company.name.toLowerCase())}/access`, state: { company: company } }}><FontAwesomeIcon className="mx-2" icon={faHeadset} />Access Account</Link> : null;
        rowActions.push(accessAccount);
        return rowActions;
    }

    onNext = () => {
        const { filter } = this.state;

        if (filter.pageIndex < filter.pageCount) {
            this.setState(prevState => ({
                loading: true,
                filter: {
                    ...prevState.filter,
                    ...{ pageIndex: +filter.pageIndex + 1 }
                }
            }), this.getCompaniesWithAccess);
        };
    }

    onPrevious = () => {
        const { filter } = this.state;
        if (filter.pageIndex > 1) {
            this.setState(prevState => ({
                loading: true,
                filter: {
                    ...prevState.filter,
                    ...{ pageIndex: +filter.pageIndex - 1 }
                }
            }), this.getCompaniesWithAccess);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            loading: true,
            filter: {
                ...prevState.filter,
                ...{ pageIndex: value }
            }
        }), this.getCompaniesWithAccess);
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    onClear = () => {
        this.setState({
            companies: [],
            loading: true,
            filter: { name: "", pageIndex: 1, pageCount: 0, total: 0 }
        }, this.getCompaniesWithAccess);
    }

    onSearch = (e) => {
        e.preventDefault();
        this.setState({ companies: [], loading: true }, this.getCompaniesWithAccess);
    }

    render() {
        let { companies, loading, pageCount, pageIndex, filter } = this.state
        return (
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <h2 className="d-inline-block">Companies</h2>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <CompanyFilter
                            onSearch={this.onSearch}
                            onFilterChange={this.onFilterChange}
                            onClear={this.onClear}
                            {...this.state.filter}
                            loading={this.state.loading} />
                    </Col>
                </Row>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <Row className="mt-4">
                            <Col md={12}>
                                <Card>
                                    <div className="table-responsive">
                                        <Table striped bordered size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Company Name</th>
                                                    <th>Company Contact</th>
                                                    <th>Date Created</th>
                                                    <th></th>
                                                    {checkPermission('CustomerServiceImpersonateCompany', <th></th>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {companies.length ? companies.map((c) => {
                                                    const contact = this.getMainContact(c.contacts);
                                                    const currentTime = new Date().getTime();
                                                    const companyNameEncoded = encodeStringForURL(c.name.toLowerCase());

                                                    const nextPath = (this.props.verifiedCompanyTimeout > currentTime)
                                                        && (c.id === this.props.verifiedCompany.id && this.props.company.isAuth) ? { pathname: `/companies/${companyNameEncoded}` }
                                                        : { pathname: `/companies/${companyNameEncoded}/verify/step1`, state: { company: c, mainContact: contact } }

                                                    return (
                                                        <tr key={c.id}>
                                                            <td>{c.name}</td>
                                                            <td>{contact.email}</td>
                                                            <td>{c.createdDate ? toLocalTimeString(c.createdDate) : "N/A"}</td>
                                                            <td className="text-center py-2">
                                                                <Button className="link-button" variant="secondary" size="sm"
                                                                    onClick={() => { this.props.history.push(nextPath); }}>View Company</Button></td>
                                                            {checkPermission('CustomerServiceImpersonateCompany', <td>{c.access ? <Actions title="Company Actions" actions={this.getActions(c)} /> : null}</td>)}
                                                        </tr>);
                                                }) :
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <Image src='Images/search-result.png' className="mx-auto d-block" width="100px" />
                                                            <b className="text-center"><p>Oh no...</p></b>
                                                            <p className="text-center">
                                                                There doesn’t seem to be any companies with that name.<br />
                                                                Have you spelt the company name correctly?
                                                            </p>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                        <Pagination
                                            onNext={this.onNext}
                                            onPrevious={this.onPrevious}
                                            onPageSelect={this.onPageSelect}
                                            pageIndex={filter.pageIndex}
                                            pageCount={filter.pageCount} />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>}
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        verifiedCompany: state.companyReducer.verifiedCompany,
        verifiedCompanyId: state.companyReducer.verifiedCompanyId,
        verifiedCompanyTimeout: state.companyReducer.verifiedCompanyTimeout,
        company: state.companyReducer.company
    }
}

const mapDispatchToProps = {
    getAllCompanies: companyActions.getAllCompanies,
    getAccessForCompany: companyActions.getAccessForCompany,
    resetCompany: companyActions.resetCompany,
    setCurrentVerificationStep: companyActions.setCurrentVerificationStep,
    getAllCompaniesWithAccessData: companyActions.getAllCompaniesWithAccessData
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies)