import React, { Component, Fragment } from 'react';
import { Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormValidator from '../common/FormValidator';
import { ErrorAlert, SuccessAlert } from '../common/Alerts';
import LoadingBar from '../common/LoadingBar';
import { companyActions } from '../../actions/companyActions';
import { encodeStringForURL } from '../../Utilities';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { FetchCategories } from './FetchCategories.js';
import { FetchWarehouses } from './FetchWarehouses.js';

class AdditionalCosts extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'amount',
                method: 'isEmpty',
                validWhen: false,
                message: 'Amount is required'
            },
            {
                field: 'amount',
                method: 'isCurrency',
                validWhen: true,
                message: 'Amount is invalid'
            },
            {
                field: 'notes',
                method: 'isEmpty',
                validWhen: false,
                message: 'Reason for Additional Cost is required'
            },
            {
                field: 'warehouseID',
                method: 'isEmpty',
                validWhen: false,
                message: 'Warehouse is required'
            },
            {
                field: 'creditActionCategoryID',
                method: 'isEmpty',
                validWhen: false,
                message: 'Category is required'
            },
        ]);

        this.state = {
            additionalCost: {
                companyEmail: this.props.companyUser.email,
                companyID: this.props.company.id,
                companyName: this.props.company.name,
                customerServiceAgent: `${this.props.user.forename} ${this.props.user.surname}`,
                amount: "",
                orderNumber: "",
                notes: "",
                warehouseID: "",
                creditActionCategoryID: ""
            },
            confirmed: false,
            created: false,
            error: false,
            loading: false,
            message: "",
            otherMessage: "",
            orderNumberError: false,
            showOther: false,
            submitted: false,
            validation: this.validator.valid(),
            warehouses: [],
            categories: []
        }
    }

    async componentDidMount() {
        FetchWarehouses(
            this.state.additionalCost.companyID, 
            (warehouses)    => this.setState({warehouses}), 
            (errorMessages) => this.setState({errorMessages})
        );

        FetchCategories(
            (categories)    => this.setState({categories}), 
            (errorMessages) => this.setState({errorMessages})
        );

        this.setState({ loading: false });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({ 
            additionalCost: { ...prevState.additionalCost, [name]: value } 
        }));
    }

    cancel = () => {
        this.props.history.push(`/companiesforcredit/${encodeStringForURL(this.props.company.name.toLowerCase())}/creditactions`);
    }

    submit = (e) => {
        e.preventDefault();

        const cost = this.state.additionalCost;
        cost.notes = cost.notes.replace(/(<([^>]+)>)/ig, "");

        const validation = this.validator.validate(cost);
        this.setState({ validation: validation });

        if (validation.isValid)
            if(cost.orderNumber !== ""){
                this.props.checkOrderWithCustomerReferenceExists(this.props.company.id, cost.orderNumber)
                    .then((exists) => {
                        const isError = !exists;
                        const isConfirmed = !isError;
                        this.setState({confirmed: isConfirmed , orderNumberError: isError });
                })
                .catch(() => { });
            }
            else {
                this.setState({ confirmed: true, orderNumberError: false })}
    }

    back = () => {
        this.setState({ confirmed: false });
    }

    confirm = () => {
        this.setState({ loading: true });
        const additionalCost = this.state.additionalCost;

        this.props.createAdHocCharge(additionalCost)
            .then((result) => {
                this.setState({
                    created: true,
                    error: false,
                    loading: false,
                    message: result.data
                });
            })
            .catch(() => {
                this.setState({
                    created: false,
                    error: true,
                    loading: false
                });
            });
    }

    return = () => {
        this.props.history.push(`/companiesforcredit/${encodeStringForURL(this.props.company.name.toLowerCase())}/creditactions`);
    }

    render() {
        const { additionalCost, confirmed, created, error, loading, message, orderNumberError, validation, warehouses, categories } = this.state;
        const companyNameEncoded = encodeStringForURL(this.props.company.name.toLowerCase());
        const selectedWarehouse = warehouses.find(e => e.id === additionalCost.warehouseID);
        const selectedCategory = categories.find(e => e.id === additionalCost.creditActionCategoryID);
        const currencySymbol = selectedWarehouse === undefined ? "" : this.props.company.companyWarehouses.find(e => e.warehouse.id === selectedWarehouse.id).warehouse.warehouseCurrency.currency.symbol;

        return (
            <Container fluid>
                <Row>
                    <Col md={5}>
                        <h2>
                            <Link style={{textDecoration: 'none'}} className="mb-4" to={`/companiesforcredit/${companyNameEncoded}/creditactions`}>Additional Costs</Link>&nbsp;
                            <FontAwesomeIcon icon={faAngleRight} /> {this.props.company.name}
                        </h2>

                        {error ? <React.Fragment >
                            <ErrorAlert title="There seems to be a problem" message="This invoice has been generated, but the company does not have a valid card stored in the Selazar platform. Once the company updates their card details, they will be charged accordingly." />
                            <Button className="btn btn-primary float-right" onClick={this.return}>Return to Company Profile</Button>
                        </React.Fragment> :

                            created ?
                                <React.Fragment >
                                    <SuccessAlert title="Success" message={message} />
                                    <Button className="btn btn-primary float-right" onClick={this.return}>Return to Company Profile</Button>
                                </React.Fragment> :

                                <React.Fragment>
                                    <div className="form-input-description">
                                        <p className="mb-4">Once you have generated an invoice, finance will be informed via email.<br/>
                                         Be careful to check all information as the customer will automatically be charged.</p>
                                    </div>

                                    {loading ? <LoadingBar /> :
                                        <Form>
                                            {orderNumberError && <React.Fragment >
                                                <ErrorAlert title="Please check the order number" message="The order number you have entered cannot be found." />
                                            </React.Fragment>}

                                            <fieldset>                                          
                                                    {confirmed ?
                                                        <Col md={6} className="p-0 m-0">
                                                            <Fragment>
                                                                <h5 className="mt-3"><strong>Summary</strong></h5><br/>

                                                                <FormGroup>
                                                                    <FormLabel htmlFor="company"><strong>Company</strong>
                                                                        <span className="d-block">{additionalCost.companyName}</span>
                                                                    </FormLabel>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormLabel htmlFor="warehouseType"><strong>Warehouse</strong>
                                                                        <span className="d-block">{selectedWarehouse.name}</span>
                                                                    </FormLabel>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormLabel htmlFor="categoryType"><strong>Category</strong>
                                                                        <span className="d-block">{selectedCategory.name}</span>
                                                                    </FormLabel>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormLabel htmlFor="amount"><strong>Invoice Amount ({currencySymbol})</strong>
                                                                        <span className="d-block">{currencySymbol}{parseFloat(additionalCost.amount).toFixed(2)}</span>
                                                                    </FormLabel>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormLabel htmlFor="orderNumber"><strong>Order Number</strong>
                                                                        <span className="d-block">{additionalCost.orderNumber ? additionalCost.orderNumber : "N/A"}</span>
                                                                    </FormLabel>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormLabel htmlFor="notes"><strong>Reason for Additional Cost</strong>
                                                                        <span className="d-block">{additionalCost.notes}</span>
                                                                    </FormLabel>
                                                                </FormGroup>
                                                            </Fragment>
                                                        </Col>

                                                        : <Col md={6} className="p-0 m-0">
                                                            <Fragment>
                                                                <FormGroup>
                                                                    <FormLabel htmlFor="warehouseType"><strong>Warehouse*</strong></FormLabel>
                                                                        <FormControl id="warehouseType" className={validation.warehouseID.isInvalid ? "input-error" : ""} name="warehouseID" as="select" value={additionalCost.warehouseID} onChange={this.handleInputChange} >
                                                                            <option key="" value="">Please select warehouse...</option>;
                                                                            {warehouses.map(warehouse => (<option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>))}
                                                                        </FormControl>
                                                                    <span className="creditActions-text-danger">{validation.warehouseID.message}</span>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormLabel htmlFor="categoryType"><strong>Category*</strong></FormLabel>
                                                                        <FormControl id="categoryType" className={validation.creditActionCategoryID.isInvalid ? "input-error" : ""} name="creditActionCategoryID" as="select" value={additionalCost.creditActionCategoryID} onChange={this.handleInputChange} >
                                                                            <option key="" value="">Please select option...</option>;
                                                                            {categories.map(category => (<option key={category.id} value={category.id}>{category.name}</option>))}
                                                                        </FormControl>
                                                                    <span className="creditActions-text-danger">{validation.creditActionCategoryID.message}</span>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormLabel htmlFor="amount"><strong>Invoice Amount ({currencySymbol})*</strong></FormLabel>
                                                                        <FormControl id="amount" type="number" name="amount" value={additionalCost.amount} onChange={this.handleInputChange}
                                                                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />
                                                                    <span className="creditActions-text-danger">{validation.amount.message}</span>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormLabel htmlFor="orderNumber"><strong>Order Number</strong> - optional</FormLabel>
                                                                        <FormControl id="orderNumber" type="text" name="orderNumber" value={additionalCost.orderNumber} onChange={this.handleInputChange} />
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormLabel htmlFor="notes"><strong>Reason for Additional Cost*</strong> <em>- 250 characters maximum</em></FormLabel>
                                                                    <textarea className="form-control" rows="4" id="notes" type="textarea" name="notes" value={additionalCost.notes} onChange={this.handleInputChange} />
                                                                    <span className="creditActions-text-danger">{validation.notes.message}</span>
                                                                </FormGroup>
                                                            </Fragment>
                                                        </Col>
                                                    }                                            

                                            </fieldset>

                                            {confirmed ?
                                                <React.Fragment className="mt-5">
                                                    <Button className="float-left mt-4" variant="secondary" onClick={this.back}>Back</Button>
                                                    <Button className="float-right mt-4" onClick={this.confirm}>Confirm Additional Cost</Button>
                                                    <Button className="float-right mt-4" variant="link" onClick={this.cancel}>Cancel</Button>
                                                </React.Fragment> :

                                                <React.Fragment>
                                                    <Button className="float-right mt-4" onClick={this.submit}>Submit Additional Cost</Button>
                                                    <Button className="float-right mt-4" variant="link" onClick={this.cancel}>Cancel</Button>
                                                </React.Fragment>
                                            }
                                        </Form>
                                    }
                                </React.Fragment>
                        }
                    </Col>
                </Row>
            </Container >
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company,
        companyUser: state.companyReducer.companyUser,
        user: state.userReducer.user
    }
}

const mapDispatchToProps = {
    createAdHocCharge: companyActions.createAdHocCharge,
    checkOrderWithCustomerReferenceExists: companyActions.checkOrderWithCustomerReferenceExists
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCosts)