import React, { Component } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Stepper from './Stepper';
import VerificationCodeForm from './VerificationCodeForm';

import { companyActions } from '../../../actions/companyActions';

import './Verification.scss';
import { encodeStringForURL } from '../../../Utilities';

class CompanyVerificationStepThree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 2,
            continue: false,
            error: false,
            inputOne: '',
            inputTwo: '',
            inputThree: '',
            inputFour: '',
            step: 3
        }
    }

    verifyCode = () => {
        const companyId = this.props.company.id;

        const { inputOne, inputTwo, inputThree, inputFour } = this.state;
        const code = inputOne + inputTwo + inputThree + inputFour;

        this.props.verifyCode(code, companyId)
            .then(() => {
                this.setState({ activeStep: 3, error: false });
            })
            .catch(() => {
                this.setState({ error: true });
            });
    }

    handleInput = (e) => {
        let { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            ...{ [name]: value }
        }));

        this.autotab(name, value);
    }

    autotab = (name, value) => {
        if (value !== '') {
            switch (name) {
                case "inputOne": document.getElementsByName("inputTwo")[0].focus();
                    break;
                case "inputTwo": document.getElementsByName("inputThree")[0].focus();
                    break;
                case "inputThree": document.getElementsByName("inputFour")[0].focus();
                    break;
                case "inputFour": document.getElementsByName("verify")[0].focus();
                    break;
                default: return null;
            }
        }
    }

    next = () => {
        this.props.setCurrentVerificationStep(this.state.step + 1);
        this.setState({ continueError: true });
    }

    previous = () => {
        this.props.setCurrentVerificationStep(this.state.step - 1);
    }

    accessCompanyDashboard = () => {
        this.props.setVerifiedCompanyDetails().then(() => {
            this.props.setVerifiedCompantyTimeout()
                .then(() => {
                    this.props.history.push(`/companies/${encodeURIComponent(this.props.company.name.toLowerCase())}`);
                });
        });
    }

    render() {
        const { activeStep, error, step } = this.state;

        const verifyCodeSuccess = () => {
            return <React.Fragment>
                <Card className="card-success mt-3 mb-2">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />Success</Card.Title>
                    <Card.Body>
                        Verification was successful
                    </Card.Body>
                </Card>
            </React.Fragment>
        }

        const verifyCodeError = () => {
            return <React.Fragment>
                <Card className="card-danger mt-3 mb-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>
                        This code doesn't seem to be correct, check with the customer
                    </Card.Body>
                </Card>
            </React.Fragment>
        }

        const steps = ['Send Code', 'Confirm Code Was Recieved', 'Enter Code'];

        return (
            this.props.currentVerificationStep !== step ? <Redirect to={`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/verify/step${this.props.currentVerificationStep}`} /> :
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h2 className="block mb-4">Company Profile</h2>
                            <Stepper steps={steps} activeStep={activeStep} />
                        </Col>
                    </Row>
                    <Row className='mt-3 mb-3'>
                        <Col md={5}>
                            <h5 className="integration-section-header">Enter Four Digit Security Code</h5>
                            <p className="mt-2">
                                <span className="d-block">
                                    Please enter the four digit security code recieved below:
                            </span>
                            </p>

                            {error && verifyCodeError()}

                            {activeStep === 2 ? <VerificationCodeForm verifyCode={this.verifyCode} handleInput={this.handleInput} /> : verifyCodeSuccess()}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            {activeStep === 2 ? <React.Fragment>
                                <Button className="btn btn-primary float-right" onClick={this.verifyCode}>Continue to next step</Button>
                                <Link to='/companies' className="link-button">
                                    <Button variant="link" className="float-right">Cancel</Button>
                                </Link>
                                <Link to='./step2' className="link-button" onClick={this.previous}>
                                    <Button className="link-button float-left" variant="secondary">Back</Button>
                                </Link>
                            </React.Fragment> :
                                <Button className="btn btn-primary float-right" onClick={this.accessCompanyDashboard}>Continue to Company Homepage</Button>
                            }
                        </Col>
                    </Row>
                </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company,
        currentVerificationStep: state.companyReducer.currentVerificationStep,
        verifiedCompanyTimeout: state.companyReducer.verifiedCompanyTimeout,
    }
}

const mapDispatchToProps = {
    setCurrentVerificationStep: companyActions.setCurrentVerificationStep,
    setVerifiedCompanyDetails: companyActions.setVerifiedCompanyDetails,
    setVerifiedCompantyTimeout: companyActions.setVerifiedCompantyTimeout,
    verifyCode: companyActions.verifyCode
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyVerificationStepThree)