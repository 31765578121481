import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './Stepper.scss';

const Stepper = ({ steps = [], activeStep = 0 }) =>
    <div className="stepper">
        {steps.length && steps.map((step, i) => {
            const current = activeStep === i;
            const active = activeStep > i;

            return (<div key={i} className={`stepper-step ${current ? "current" : active ? "active" : ""}`}>
                {current && <div className="stepper-circle-inner"></div>}
                <div className="stepper-circle">{active && <div className="stepper-circle-icon"><FontAwesomeIcon icon={faCheck} size="lg" /></div>}</div>
                <div className="stepper-title">{step}</div>
            </div>)
        })}
    </div>

export default Stepper;
