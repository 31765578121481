// Components
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_LOGOUT = 'USER_LOGOUT';

// Components/Account/Password
export const USER_SEND_PASSWORD_RESET = 'USER_SEND_PASSWORD_RESET';
export const USER_SEND_PASSWORD_RESET_SUCCESS = 'USER_SEND_PASSWORD_RESET_SUCCESS';
export const USER_SEND_PASSWORD_RESET_FAILURE = 'USER_SEND_PASSWORD_RESET_FAILURE';

export const USER_GET_PASSWORD_RESET_REQUEST = 'USER_GET_PASSWORD_RESET_REQUEST';
export const USER_GET_PASSWORD_RESET_REQUEST_SUCCESS = 'USER_GET_PASSWORD_RESET_REQUEST_SUCCESS';
export const USER_GET_PASSWORD_RESET_REQUEST_FAILURE = 'USER_GET_PASSWORD_RESET_REQUEST_FAILURE';

export const USER_PASSWORD_CHANGE = 'USER_PASSWORD_CHANGE';
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS';
export const USER_PASSWORD_CHANGE_FAILURE = 'USER_PASSWORD_CHANGE_FAILURE';

// Components/Dashboard
export const USER_GET = 'USER_GET';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_FAILURE = 'USER_GET_FAILURE';

// Components/Settings/Security
export const USER_GET_FILTERED_FOR_COMPANY = 'USER_GET_FILTERED_FOR_COMPANY';
export const USER_GET_FILTERED_FOR_COMPANY_SUCCESS = 'USER_GET_FILTERED_FOR_COMPANY_SUCCESS';
export const USER_GET_FILTERED_FOR_COMPANY_FAILURE = 'USER_GET_FILTERED_FOR_COMPANY_FAILURE';