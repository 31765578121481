import React, { Component } from 'react';
import { Button, Card, Col, Container, Image, Row, Table, } from 'react-bootstrap';
import { connect } from 'react-redux';

import { extractData } from '../../Consumer';
import { toLocalTimeString, encodeStringForURL } from '../../Utilities';
import LoadingBar from '../common/LoadingBar';
import Pagination from '../common/tables/Pagination';
import CompanyFilter from '../company/CompanyFilter';

import { companyActions } from '../../actions/companyActions';

class CompaniesForCredit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            totalCount: 0,
            pageIndex: 1,
            pageCount: 1,
            companies: [],
            filter: {
                name: ""
            }
        }
    }

    componentDidMount() {
        // Reset route blocking for company verification 
        const { history } = this.props;
        history.block(() => { })

        this.getCompanies();
    }

    getCompanies() {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };

        this.props.getAllCompanies(filter, pageIndex)
            .then((result) => {
                if (result.data != null) {
                    const data = extractData(result) || [];
                    this.setState({
                        companies: data.companies,
                        totalCount: data.length,
                        pageCount: data.pageCount,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getMainContact = (company) => {
        const mainContactTypeID = "1c60bafa-5a3e-441d-a520-41b7544a7673";
        return company.find(c => c.contact.contactTypeID === mainContactTypeID).contact;
    }

    onNext = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: +pageIndex + 1, loading: true }, this.getCompanies);
        }
    }

    onPrevious = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: +pageIndex - 1, loading: true }, this.getCompanies);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.getCompanies);
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    onClear = () => {
        this.setState({
            companies: [],
            loading: true,
            filter: { name: "" }
        }, this.getCompanies);
    }

    onSearch = (e) => {
        e.preventDefault();
        this.setState({ companies: [], loading: true }, this.getCompanies);
    }

    render() {
        const { companies, loading, pageCount, pageIndex } = this.state

        return (
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <h2 className="d-inline-block">Credit Actions Company Lookup</h2>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <CompanyFilter
                            onSearch={this.onSearch}
                            onFilterChange={this.onFilterChange}
                            onClear={this.onClear}
                            {...this.state.filter}
                            loading={this.state.loading} />
                    </Col>
                </Row>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <Row className="mt-4">
                            <Col md={12}>
                                <Card>
                                    <div className="table-responsive">
                                        <Table striped bordered size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Company Name</th>
                                                    <th>Company Contact</th>
                                                    <th>Date Created</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {companies.length ? companies.map((c) => {
                                                    const contact = this.getMainContact(c.contacts);
                                                    const creditActionPath = { pathname: `/companiesforcredit/${encodeStringForURL(c.name.toLowerCase())}/creditactions`, state: { company: c, mainContact: contact } }

                                                    return (
                                                        <tr key={c.id}>
                                                            <td>{c.name}</td>
                                                            <td>{contact.email}</td>
                                                            <td>{c.createdDate ? toLocalTimeString(c.createdDate) : "N/A"}</td>
                                                            <td className="text-center py-2">
                                                                <Button className="link-button" variant="secondary" size="sm"
                                                                    onClick={() => { this.props.history.push(creditActionPath); }}>Credit Actions</Button>
                                                            </td>
                                                        </tr>);
                                                }) :
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <Image src='Images/search-result.png' className="mx-auto d-block not-found-icon" />
                                                            <b className="text-center"><p>Oh no...</p></b>
                                                            <p className="text-center">
                                                                There doesn’t seem to be any companies with that name.<br />
                                                                Have you spelt the company name correctly?
                                                            </p>
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                        <Pagination
                                            onNext={this.onNext}
                                            onPrevious={this.onPrevious}
                                            onPageSelect={this.onPageSelect}
                                            pageIndex={pageIndex}
                                            pageCount={pageCount} />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>}
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company
    }
}

const mapDispatchToProps = {
    getAllCompanies: companyActions.getAllCompanies
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesForCredit)