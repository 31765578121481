import React, { Component } from 'react';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { extractData } from '../../Consumer';

import { companyActions } from '../../actions/companyActions';
import { encodeStringForURL } from '../../Utilities';

class CompanyDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            company: {
                name: ''
            }
        }

        this.unblock = () => {}
    }

    componentDidMount() {
        const { history } = this.props;

        this.unblock = history.block((location) => {
            if ((location.pathname === '/companies' || location.pathname === '/companiesforcredit' || location.pathname === '/') && this.props.company.isAuth) {
                if (!window.confirm(`Are you sure you want to log out of ${this.props.company.name}? To log back into this account you will need to go through the verification process again.`)) {
                    return false;
                } else {
                    this.props.resetCompany().then(() => { return true });
                }
            }

            return true;
        });

        if (!this.props.company) {
            const companyId = this.props.company.id;

            this.props.getCompanyById(companyId)
                .then((result) => {
                    this.setState({ company: extractData(result) });
                })
                .catch(() => { });
        } else {
            this.setState({ company: this.props.company });
        }
    }

    componentWillUnmount() {
        this.unblock();
    }

    render() {
        const { company } = this.state;
        const sections = [
            {
                title: 'Company Profile',
                description: 'View and edit information on the company',
                link: `/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/profile`,
                icon: 'Images/company-profile.svg'
            },
            {
                title: 'Invoices',
                description: 'View the invoices and create order notes',
                link: `/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/invoicing`,
                icon: 'Images/invoices.svg'
            },
            {
                title: 'Order History',
                description: 'View the full order history of the company',
                link: `/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/orderhistory/processing`,
                icon: 'Images/order-history.svg'
            },
            {
                title: 'Settings',
                description: 'View and change the company\'s settings',
                link: `/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/settings`,
                icon: 'Images/settings_1.svg'
            }
        ];

        return (
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="link" to='/companies' onClick={this.resetCompany}>
                            Companies
                        </Link> <FontAwesomeIcon icon={faAngleRight} /> {company.name} <h2 className="d-block mt-3">{company.name}</h2>
                    </Col>
                    <Link to='/companies' className="btn mb-3">
                        <Button variant='secondary' onClick={this.resetCompany}>Log Out of {company.name}</Button>
                    </Link>
                </Row>
                <Row>
                    {sections.map((s, i) => {
                        return (
                            <Col md={4} key={i} className='mb-4'>
                                <Link to={s.link} className='text-decoration-none'>
                                    <Card className='p-3'>
                                        <Row>
                                            <Col sm={3} className='d-flex align-items-center'>
                                                <Image src={s.icon} className="card-icon" /></Col>
                                            <Col sm={9}><h5>{s.title}</h5>
                                                <p>{s.description}</p></Col>
                                        </Row>
                                    </Card>
                                </Link>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.verifiedCompany,
        isAuth: state.companyReducer.isAuth,
        verifiedCompanyTimeout: state.companyReducer.verifiedCompanyTimeout
    }
}

const mapDispatchToProps = {
    getCompanyById: companyActions.getCompanyById,
    resetCompany: companyActions.resetCompany,
    setCurrentVerificationStep: companyActions.setCurrentVerificationStep,
    setVerifiedCompanyDetails: companyActions.setVerifiedCompanyDetails,
    setVerifiedCompantyTimeout: companyActions.setVerifiedCompantyTimeout
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDashboard)