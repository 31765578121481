import React, { Component } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { encodeStringForURL } from '../../../Utilities';

class Settings extends Component {
    render() {
        const sections = [
            {
                title: 'Security',
                description: 'Edit customer security information, including passwords',
                link: `/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/settings/security`,
                icon: 'Images/settings.svg'
            }
        ];

        return (
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="link" to={'/companies'}>Companies</Link>&nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />&nbsp;
                        <Link className="link" to={`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}`}>{this.props.company.name}</Link>&nbsp;
                        <FontAwesomeIcon icon={faAngleRight} /> Settings
                    </Col>
                </Row>
                <Row>
                    {sections.map((s, i) => {
                        return (
                            <Col md={4} key={i} className='mb-4 mt-4'>
                                <Link to={s.link} className='text-decoration-none'>
                                    <Card className='p-3'>
                                        <Row className='mt-2'>
                                            <Col sm={3}><Image src={s.icon} width='80px' /></Col>
                                            <Col sm={9}><h5>{s.title}</h5>
                                                <p>{s.description}</p></Col>
                                        </Row>
                                    </Card>
                                </Link>
                            </Col>
                        )}
                    )}
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.verifiedCompany
    }
}

export default connect(mapStateToProps, null)(Settings)