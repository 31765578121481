import React from 'react';
import { Button, Card, Image, Table } from 'react-bootstrap';

import { toLocalTimeString } from '../../../Utilities';
import SelazarLinkContainer from '../../common/SelazarLinkContainer';
import Pagination from '../../common/tables/Pagination';

const OrderHistoryTable = ({ orderHistories, onNext, onPrevious, onPageSelect, pageIndex, pageCount }) => {
    return (
        <Card>
            <div className="table-responsive">
                <Table striped bordered size="sm">
                    <thead>
                        <tr>
                            <th>Order Number</th>
                            <th>Tracking Ref</th>
                            <th>Customer Name</th>
                            <th>Date Created</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderHistories.length ? orderHistories.map((o) => {
                            return (
                                <tr key={o.id}>
                                    <td>{o.customerReference}</td>
                                    <td>{o.parcelReferences.length > 0 ? o.parcelReferences.join(', ') : "N/A"}</td>
                                    <td>{o.customerOrganisationPersonName}</td>
                                    <td>{o.createdDate ? toLocalTimeString(o.createdDate) : "N/A"}</td>
                                    <td>
                                        { o.status !== null ?
                                                <span className={'text-nowrap' +
                                                    o.status.name === 'Awaiting Collection' ? 'text-status-blue' :
                                                    o.status.name === 'Awaiting Fulfilment' ? 'text-status-yellow' :
                                                        o.status.name === 'Processing Failed' ? 'text-status-red' : 'text-status-blue'}>
                                                    {o.status.name}
                                                </span> : <span>N/A</span> }
                                    </td>
                                    <td className="text-center py-2">
                                        <SelazarLinkContainer
                                            to={{
                                                pathname: `./${o.customerReference}`,
                                                state: { orderHistory: o }
                                            }} exact>
                                            <Button className="link-button" variant="secondary" size="sm">View Order</Button>
                                        </SelazarLinkContainer>
                                    </td>
                                </tr>);
                        }) :
                            <tr>
                                <td colSpan={7}>
                                    <Image src='Images/search-result.png' className="mx-auto d-block" width="100px" />
                                    <p className="text-center">Order history not available.</p>
                                </td>
                            </tr>}
                    </tbody>
                </Table>
                <Pagination onNext={onNext} onPrevious={onPrevious} onPageSelect={onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
            </div>
        </Card>
    );
}

export default OrderHistoryTable;