import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Row, Col, Modal, Button, FormGroup, FormLabel } from 'react-bootstrap';
import { encodeStringForURL, toLocalTimeString } from '../../../Utilities';
import Slider from '../../common/Slider';

const SubNav = ({ showCompanyDetails, onNavClick }) =>
    <div>
        {showCompanyDetails ?
            <Breadcrumb>
                <Breadcrumb.Item active>
                    Company Details
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={onNavClick}>
                    Billing
                </Breadcrumb.Item>
            </Breadcrumb>
            : <Breadcrumb>
                <Breadcrumb.Item onClick={onNavClick}>
                    Company Details
            </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Billing
            </Breadcrumb.Item>
            </Breadcrumb>
        }
    </div>

const CompanyDetails = ({ name, createdDate }) =>
    <section>
        <h5 className="integration-section-header">Company Details</h5>
        <p className="mt-3">
            <strong>Company Name</strong>
            <span className="d-block">{name}</span>
            <Link to={{ pathname: `/companies/${encodeStringForURL(name.toLowerCase())}/profile/edit/name`, state: { mode: 'name' } }} className="d-block">Edit Company Name</Link>
        </p>
        <p>
            <strong>Joined Selazar</strong>
            <span className="d-block">{createdDate !== null ?
                toLocalTimeString(createdDate) : 'N/A'}</span>
        </p>
    </section>

const CompanyOwnerDetails = ({ forename, surname, email, name }) =>
    <section>
        <h5 className="integration-section-header">Company Owner Details</h5>
        <p className="mt-3">
            <strong>Forename</strong>
            <span className="d-block">{forename}</span>
        </p>
        <p className="mt-3">
            <strong>Surname</strong>
            <span className="d-block">{surname}</span>
        </p>
        <p className="mt-3">
            <strong>Email</strong>
            <span className="d-block">{email}</span>
            <Link to={{ pathname: `/companies/${encodeStringForURL(name.toLowerCase())}/profile/edit/email`, state: { mode: 'email' } }} className="d-block">Edit Email</Link>
        </p>
    </section>

const CompanyBillingDetails = ({ addressLine1, addressLine2, town, county, postcode, name }) =>
    <section>
        <h5 className="integration-section-header">Company Billing Details</h5>
        <p className="mt-3">
            <strong>Billing Address</strong>
            <span className="d-block">{addressLine1}</span>
            <span className="d-block">{addressLine2}</span>
            <span className="d-block">{town}</span>
            <span className="d-block">{county}</span>
            <span className="d-block">{postcode}</span>
            <Link to={{ pathname: `/companies/${encodeStringForURL(name.toLowerCase())}/profile/edit/billing`, state: { mode: 'billing' } }} className="d-block">Edit Address</Link>
        </p>
    </section>

const CardDetails = ({ holderName, cardNumber, status }) =>
    <section>
        <h5 className="integration-section-header">Card Details</h5>
        <p className="mt-3">
            <strong>Cardholder's Name</strong>
            <span className="d-block">{holderName ? holderName : "N/A"}</span>
        </p>
        <p className="mt-3">
            <strong>Card Number</strong>
            <span className="d-block">{cardNumber ? cardNumber : "N/A"}</span>
        </p>
        <p className="mt-3 mb-4">
            <strong className="d-block">Card Status</strong>
            {!status ? <span className="text-status-green">Active</span>
                : <span className="text-status-red">Not Active</span>}
        </p>
    </section>

const PaymentTerms = ({ directDebitEnabled, paymentTerm, showDebitModal }) =>
    <section>
        <h5 className="integration-section-header">Payment Terms</h5>
        <div className="form-input-description mt-2">
            <Row>
                <Col sm={12} md={6}>
                    <p className="my-1">Enabling payment terms allows this customer to add a bank account as their payment method, and changes them from a daily collection schedule to weekly.</p>
                </Col>
            </Row>
        </div>
        <p className="mt-3">
            <strong>Direct Debit</strong>
            <Slider id="directDebit" name="directDebit" label={directDebitEnabled ? "Enabled" : "Disabled"} checked={directDebitEnabled} onChange={showDebitModal} />
        </p>
        {paymentTerm !== null &&
            <React.Fragment>
                <strong>Payment Term</strong>

                <div className="form-input-description my-2">
                    <Row>
                        <Col sm={12} md={6}>
                            <p className="my-1">You are unable to change the payment type without disabling the current direct debit.</p>
                        </Col>
                    </Row>
                </div>
                <p>{paymentTerm} Days</p>
            </React.Fragment>
        }
    </section>

const EnablePaymentModal = ({ handleClose, handleConfirmClose, handleConfirmAction, paymentTerms, handleInputChange }) =>
    <Modal centered show onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Enable Payment Terms Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Enabling payment terms allows this customer to add a bank account as their payment method, and changes them from a daily collection schedule to weekly.</p>
            <p><strong>Payment Term</strong></p>
            <div className="form-input-description my-2">
                <p className="my-1">This is the duration between the collections advice and when payment will be attempted. It should match the customer's contractual terms.</p>
            </div>
            <FormGroup className="custom-control custom-radio mb-2">
                <input id="zero" className="custom-control-input" name="zero" type="radio" checked={paymentTerms === 0 || paymentTerms === null} onChange={() => handleInputChange(0)} />
                <FormLabel className="custom-control-label" htmlFor="zero">0 Days</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-2">
                <input id="seven" className="custom-control-input" name="seven" type="radio" checked={paymentTerms === 7} onChange={() => handleInputChange(7)} />
                <FormLabel className="custom-control-label" htmlFor="seven">7 Days (1 week)</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-2">
                <input id="fourteen" className="custom-control-input" name="fourteen" type="radio" checked={paymentTerms === 14} onChange={() => handleInputChange(14)} />
                <FormLabel className="custom-control-label" htmlFor="fourteen">14 Days (2 weeks)</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-2">
                <input id="twentyEight" className="custom-control-input" name="twentyEight" type="radio" checked={paymentTerms === 28} onChange={() => handleInputChange(28)} />
                <FormLabel className="custom-control-label" htmlFor="twentyEight">28 Days (4 weeks)</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-2">
                <input id="fortytwo" className="custom-control-input" name="fortytwo" type="radio" checked={paymentTerms === 42} onChange={() => handleInputChange(42)} />
                <FormLabel className="custom-control-label" htmlFor="fortytwo">42 Days (6 weeks)</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-2">
                <input id="fiftysix" className="custom-control-input" name="fiftysix" type="radio" checked={paymentTerms === 56} onChange={() => handleInputChange(56)} />
                <FormLabel className="custom-control-label" htmlFor="fiftysix">56 Days (8 weeks)</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-2">
                <input id="seventy" className="custom-control-input" name="seventy" type="radio" checked={paymentTerms === 70} onChange={() => handleInputChange(70)} />
                <FormLabel className="custom-control-label" htmlFor="seventy">70 Days (10 weeks)</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-2">
                <input id="eightyfour" className="custom-control-input" name="eightyfour" type="radio" checked={paymentTerms === 84} onChange={() => handleInputChange(84)} />
                <FormLabel className="custom-control-label" htmlFor="eightyfour">84 Days (12 weeks)</FormLabel>
            </FormGroup>
        </Modal.Body>
        <Modal.Footer className="pt-0">
            <Button variant="link" onClick={handleConfirmClose}>Do Not Enable</Button>
            <Button onClick={handleConfirmAction}>Confirm and Enable</Button>
        </Modal.Footer>
    </Modal>

const VatExempt = ({ vatEnabled, showVatModal }) =>
    <section>
        <h5 className="integration-section-header">VAT Details</h5>
        <div className="form-input-description mt-2">
            <Row>
                <Col sm={12} md={6}>
                    <p className="my-1">When a retailer is VAT exempt, the VAT percentage of the retailer will be set automatically by the system to 0%.</p>
                </Col>
            </Row>
        </div>
        <p className="mt-3">
            <strong>VAT Exempt</strong>
            <Slider id="directDebit" name="directDebit" label={vatEnabled ? "Enabled" : "Disabled"} checked={vatEnabled} onChange={showVatModal} />
        </p>
    </section>

export {
    SubNav,
    CompanyDetails,
    CompanyOwnerDetails,
    CompanyBillingDetails,
    CardDetails,
    PaymentTerms,
    EnablePaymentModal,
    VatExempt
}