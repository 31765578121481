import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormValidator from '../../common/FormValidator';
import { Form, FormGroup, FormLabel, FormControl, Col, Button, Row, Card, Image, Container } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { userActions } from '../../../actions/userActions';

const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN;

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: 'Email is required'
            }
        ]);

        this.state = {
            form: { email: '' },
            recaptcha: false,
            recaptchaError: false,
            loading: false,
            validation: this.validator.valid(),
            error: false,
            message: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    handleInputChange(e) {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleRecaptcha = () => {
        this.setState({ recaptcha: true });
    }

    handleSubmit(event) {
        event.preventDefault();

        let { form, recaptcha } = this.state;

        const validation = this.validator.validate(form);

        this.setState({
            validation: validation,
            recaptchaError: !recaptcha
        });

        this.submitted = true;

        if (validation.isValid && recaptcha) {
            this.props.resetPassword(form.email)
                .then(() => {
                    this.setState({ error: false });
                    this.props.history.push({ pathname: "/account/password/result", state: { email: form.email } });
                })
                .catch((error) => {
                    this.setState({ error: true, message: error.message });
                });
        }
    }

    renderForm(form) {
        let validation = this.submitted ?
            this.validator.validate(form) :
            this.state.validation;

        let { loading, error, message, recaptchaError } = this.state;

        return (
            <Row className="justify-content-center row-form-adjustedheight">
                <Col md={6} className="align-self-center">
                    <Card className="p-5">
                        <Image src='Images/logo.png' className="mx-auto" width="220px" />
                        <h5 className="mb-4 mt-3 text-center">Customer Service Portal</h5>
                        <Form onSubmit={this.handleSubmit}>
                            {error &&
                                <Card className="card-danger mb-3">
                                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                                    <Card.Body>{message}</Card.Body>
                                </Card>}
                            <span>Please enter your email below and we will send you a link that will allow you to reset your password.</span>
                            <FormGroup className="mt-3">
                                <FormLabel className="font-weight-bold">Email</FormLabel>
                                <FormControl disabled={loading} id="emailField" type="email" name="email" value={form.email} onChange={this.handleInputChange} />
                                <span className="text-danger">{validation.email.message}</span>
                            </FormGroup>
                            <Container className="d-flex justify-content-center my-4">
                                <ReCAPTCHA sitekey={captchaKey} onChange={this.handleRecaptcha} />
                            </Container>
                            {recaptchaError && <p className="text-danger">Please complete captcha</p>}

                            <Button size="lg" type="submit" block disabled={loading} value="Submit">Send password reset email</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        );
    }

    render() {
        const { form } = this.state;
        let contents = this.renderForm(form);

        return (
            <Container className="h-100">
                {contents}
            </Container>
        );
    }

}

const mapDispatchToProps = {
    resetPassword: userActions.resetPassword
}

export default connect(null, mapDispatchToProps)(ForgotPassword)