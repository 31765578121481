import React from 'react';
import { Button, Col, Form, FormGroup, Row, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class UserFilter extends React.Component {
    render() {
        const { name, onFilterChange, onClear, onSearch } = this.props;

        return (
            <Form onSubmit={onSearch}>
                <FormGroup>
                    <Row>
                        <Col md={3}>
                            <InputGroup>
                                <FormControl
                                    id="nameField"
                                    className="input-filter"
                                    type="text"
                                    name="name"
                                    placeholder="Search by user name"
                                    value={name}
                                    onChange={onFilterChange} />
                                <InputGroup.Append>
                                    <InputGroup.Text className="input-filter-icon">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col md={9}>
                            <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
                            <Button variant="link" className="float-right" onClick={onClear}>Clear filter</Button>
                        </Col>
                    </Row>
                </FormGroup>
            </Form>
        );
    }
}

export default UserFilter;