import React, { Component } from 'react';
import { Col, Button, Image, Container, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ResetRequested = (email) => {
    return (
        <React.Fragment>
            <p className="mb-0 text-center">We have emailed a password reset link to <strong>{email}</strong>, follow the link in the email to reset your password.</p>
            <Image src='Images/email.png' className="mx-auto mb-4" width="220px" />
            {LoginButton}
        </React.Fragment>
    );
}

const LoginButton = (
    <Link to='/' className="link-button">
        <Button variant="primary" size="lg" block>Login</Button>
    </Link>
);

const ResetSuccess = (
    <React.Fragment>
        <h5>Password Reset</h5>
        <Card className="card-success mt-3 mb-4">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />Success</Card.Title>
            <Card.Body>Password has been changed successfully.</Card.Body>
        </Card>
        {LoginButton}
    </React.Fragment>
);

class ResetResult extends Component {
    render() {
        return (
            <Container className="h-100">
                <Row className="justify-content-center row-form-adjustedheight">
                    <Col md={6} className="align-self-center">
                        <Card className="p-5">
                            <Image src='Images/logo.png' className="mx-auto" width="220px" />
                            <h5 className="mb-4 mt-3 text-center">Customer Service Portal</h5>
                            {this.props.location.state.changed ? ResetSuccess : ResetRequested(this.props.location.state.email)}
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ResetResult;