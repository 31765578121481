import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';

import './Pagination.scss';

class Pagination extends Component {
    render() {
        const { onPrevious, onNext, onPageSelect, pageCount, pageIndex } = this.props;
        const options = Array(pageCount).fill().map((_, i) => <option key={i + "_page"} value={i + 1}>{i + 1}</option>)

        return (
            <div className="pagination justify-content-center mb-3 mt-1">
                <span className="mt-2 mr-3 pagination-link" onClick={onPrevious}>{"< Previous"}</span>
                <FormControl as="select" defaultValue={pageIndex} onChange={onPageSelect}>
                    {options}
                </FormControl>
                <span className="mt-2 ml-2">of {pageCount}</span>
                <span className="mt-2 ml-3 pagination-link" onClick={onNext}>{"Next >"}</span>
            </div>
        )
    }
}

export default Pagination;