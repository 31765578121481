import React from 'react';
import { Button, Col, Form, FormControl, FormGroup, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

class OrderFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    loading(loading) {
        return this.props.onLoading(loading);
    }

    clear = () => {
        this.loading(true);
        return this.props.onClear();
    }

    render() {
        const { orderNumber, dateFrom, dateTo, onSearch, onFilterChange } = this.props;

        return (
            <Form onSubmit={onSearch}>
                <FormGroup>
                    <Row>
                        <Col md={3}>
                            <InputGroup>
                                <FormControl
                                    id="nameField"
                                    className="input-filter"
                                    type="text"
                                    name="orderNumber"
                                    placeholder="Search by Order Number"
                                    value={orderNumber}
                                    onChange={onFilterChange} />
                                <InputGroup.Append>
                                    <InputGroup.Text className="input-filter-icon">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col md={4}>
                            <InputGroup>
                                <FormControl id="dateFromField" className="input-filter" type="date" name="dateFrom" value={dateFrom} onChange={onFilterChange} />
                                <InputGroup.Append>
                                    <InputGroup.Text className="input-filter-icon">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </InputGroup.Text>
                                </InputGroup.Append>

                                <FormControl id="dateToField" className="input-filter" type="date" name="dateTo" value={dateTo} onChange={onFilterChange} />
                                <InputGroup.Append>
                                    <InputGroup.Text className="input-filter-icon">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Button variant="primary" className="float-right" type="submit" >Apply filter</Button>
                    <Button variant="link" className="float-right" onClick={this.clear} >Clear filter</Button>
                </FormGroup>
            </Form>
        );
    }
}

export default OrderFilter;