import React, { Component } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import Stepper from './Stepper';

import { companyActions } from '../../../actions/companyActions';

class CompanyVerificationStepOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contactEmail: '',
            codeSuccess: false,
            companyId: '',
            continueError: false,
            error: '',
            step: 1
        }
    }

    componentDidMount() {
        // Reset route blocking for company verification 
        const { history } = this.props;
        history.block(() => { });

        const user = this.props.location.state !== undefined ?
            this.props.location.state.mainContact : this.props.user;

        const company = this.props.location.state !== undefined ?
            this.props.location.state.company : this.props.company;

        this.props.setCurrentVerificationStep(this.state.step);
        this.props.setVerificationUser(user);
        this.props.setCompany(company);

        this.setState({ contactEmail: user.email, companyId: company.id });
    }

    sendCode = () => {
        const email = this.state.contactEmail;
        const companyId = this.state.companyId;

        this.props.sendVerificationCode(companyId, email)
            .then((result) => {
                this.props.setVerificationCode(result);
            })
            .then(() => {
                this.setState({
                    continueError: false,
                    codeSuccess: true
                });
            })
            .catch(() => {
                this.setState({
                    codeSuccess: false
                });
            });
    }

    next = () => {
        if (this.state.codeSuccess) {
            this.props.setCurrentVerificationStep(this.state.step + 1);
            this.props.history.push('./step2');
        } else {
            this.setState({ continueError: true });
        }
    }

    render() {
        const { codeSent, codeSuccess, contactEmail, continueError } = this.state;

        const sendCodeSuccess = () => {
            return <React.Fragment>
                <Card className="card-success mt-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />Success</Card.Title>
                    <Card.Body>
                        You have sent a four digit code successfully
                    </Card.Body>
                </Card>
            </React.Fragment>
        }

        const codeNotSentError = () => {
            return <React.Fragment>
                <Card className="card-danger mt-3 mb-2">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>
                        You have not sent the four digit code. You need to send the code before you continue
                    </Card.Body>
                </Card>
            </React.Fragment>
        }

        const steps = ['Send Code', 'Confirm Code Was Recieved', 'Enter Code'];

        return (
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <h2 className="block mb-4">Company Profile</h2>
                        <Stepper steps={steps} activeStep={0} />
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={5}>
                        <section>
                            <h5 className="integration-section-header">Verification Process</h5>
                            <p className="mt-2">
                                <span className="d-block">
                                    To prevent fraud we must verify that you are speaking to someone from
                                    within the company. To do this we use email verification.
                                </span>
                            </p>
                        </section>
                        <section>
                            <h5 className="integration-section-header mt-4 mb-2">Sending Four Digit Security Code</h5>
                            <strong>Inform the customer of the following:</strong>
                            <span className="d-block mt-2">I am going to send you a four digit code to<strong> {contactEmail}</strong>.</span>

                            {continueError && codeNotSentError()}

                            {codeSuccess && sendCodeSuccess()}

                            {(!codeSent && !codeSuccess) && <Button className="btn btn-primary float-left mt-3" onClick={this.sendCode}>
                                Send 4 digit code
                                </Button>}
                        </section>
                    </Col>
                </Row>
                <Row>
                    <Col md={5} className='mt-4'>
                        <Button className="btn btn-primary float-right" onClick={this.next}>Continue to next step</Button>
                        <Link to='/companies'>
                            <Button variant="link" className="float-right">Cancel</Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company,
        user: state.companyReducer.companyUser,
        verifiedCompanyTimeout: state.companyReducer.verifiedCompanyTimeout
    }
}

const mapDispatchToProps = {
    sendVerificationCode: companyActions.sendVerificationCode,
    setCompany: companyActions.setCompany,
    setCurrentVerificationStep: companyActions.setCurrentVerificationStep,
    setVerificationCode: companyActions.setVerificationCode,
    setVerificationUser: companyActions.setVerificationUser
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyVerificationStepOne)