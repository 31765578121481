import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ErrorAlert = ({ title = "There seems to be a problem", message }) =>
    <Card className={`card-danger mb-3`}>
        <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />{title}</Card.Title>
        <Card.Body>{message}</Card.Body>
    </Card>

const SuccessAlert = ({ title, message }) =>
    <Card className={`card-success mb-3`}>
        <Card.Title><FontAwesomeIcon icon={faCheckCircle} />{title}</Card.Title>
        <Card.Body>{message}</Card.Body>
    </Card>

export {
    ErrorAlert,
    SuccessAlert
}