import React, { Component } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { checkPermission, encodeStringForURL } from '../../Utilities';

import { companyActions } from '../../actions/companyActions';

class CreditActions extends Component {
    componentDidMount() {
        const company = this.props.location.state !== undefined ?
            this.props.location.state.company : this.props.company;

        const user = this.props.location.state !== undefined ?
            this.props.location.state.mainContact : this.props.user;

        this.props.setCompany(company);
        this.props.setVerificationUser(user);
    }

    render() {
        const sections = [
            {
                title: 'Add Additional Costs',
                description: 'Create invoices for additional costs',
                link: `/companiesforcredit/${encodeStringForURL(this.props.company.name.toLowerCase())}/creditactions/additionalcosts`,
                icon: 'Images/add-additional-costs.svg'
            },
            {
                title: 'Credit Notes',
                description: 'View and create credit notes',
                link: `/companiesforcredit/${encodeStringForURL(this.props.company.name.toLowerCase())}/creditactions/creditnotes`,
                icon: 'Images/credit-notes.svg'
            }
        ];

        return (
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="link" to={'/companiesforcredit'}>Credit Actions Company Lookup</Link>&nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />&nbsp;Credit Actions
                    </Col>
                </Row>
                <Row>
                    {sections.map((s, i) => {
                        return (
                            checkPermission('CustomerServiceCreateAdHocCharge', <Col md={4} key={i} className='mb-4 mt-4'>
                                <Link to={s.link} className='text-decoration-none'>
                                    <Card className='p-2'>
                                        <Row className='mt-2'>
                                            <Col sm={3}><Image src={s.icon} className="d-inline-block card-icon" /></Col>
                                            <Col sm={9}><h5 className="d-inline-block">{s.title}</h5>
                                                <p>{s.description}</p></Col>
                                        </Row>
                                    </Card>
                                </Link>
                            </Col>)
                        );
                    }
                    )}
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company,
        user: state.companyReducer.companyUser,
    }
}

const mapDispatchToProps = {
    setCompany: companyActions.setCompany,
    setVerificationUser: companyActions.setVerificationUser
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditActions)