import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { extractData } from '../../../Consumer';
import { toLocalTimeString } from '../../../Utilities';
import { ErrorAlert } from '../../common/Alerts';
import LoadingBar from '../../common/LoadingBar';

import { companyActions } from '../../../actions/companyActions';

import './CompanyAccess.scss';

const mainContactType = "1c60bafa-5a3e-441d-a520-41b7544a7673";

const CompanyDetails = ({ company }) => (
    <React.Fragment>
        <h5 className="mb-3">Company Details</h5>
        <p className="m-0"><strong>Company Name</strong></p>
        <p>{company.name}</p>
        <p className="m-0"><strong>Company Contact</strong></p>
        <p>{company.contacts ? company.contacts.find(c => c.contact.contactTypeID === mainContactType).contact.email : "N/A"}</p>
        <p className="m-0"><strong>Date Created</strong></p>
        <p>{company.createdDate ? toLocalTimeString(company.createdDate) : "N/A"}</p>
    </React.Fragment>
)

const TemporaryLoginDetails = ({ temporaryLoginDetails, getTemporaryLogin, showPassword, handleToggleShowHidePassword }) => (
    <React.Fragment>
        <h5 className="mt-4 mb-3">Temporary Login Details</h5>
        {temporaryLoginDetails.email && temporaryLoginDetails.password ?
            <React.Fragment>
                <p className="m-0"><strong>Email Address</strong></p>
                <p>{temporaryLoginDetails.email}</p>
                <p className="m-0"><strong>Password</strong></p>
                <p>{showPassword ? temporaryLoginDetails.password :
                    <React.Fragment>
                        <span className="text-password-hidden" />
                        <span className="text-password-hidden" />
                        <span className="text-password-hidden" />
                        <span className="text-password-hidden" />
                        <span className="text-password-hidden" />
                        <span className="text-password-hidden" />
                        <span className="text-password-hidden" />
                        <span className="text-password-hidden" />
                        <span className="text-password-hidden" />
                        <span className="text-password-hidden" />
                    </React.Fragment>
                }</p>
                <Button variant="secondary" onClick={handleToggleShowHidePassword}>{showPassword ? <React.Fragment>Hide<FontAwesomeIcon className="ml-2" icon={faEyeSlash} /></React.Fragment> : <React.Fragment>Show<FontAwesomeIcon className="ml-2" icon={faEye} /></React.Fragment>}</Button>
            </React.Fragment>
            : <Button variant="secondary" onClick={getTemporaryLogin}>Generate Temporary Login</Button>}
    </React.Fragment>
)

class CompanyAccess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            showPassword: false,
            company: {},
            temporaryLoginDetails: {
                email: "",
                password: ""
            }
        }
    }

    componentDidMount() {
        const company = this.props.location.state.company;
        this.setState({ company: company, loading: false });
    }

    getTemporaryLogin(id) {
        this.setState({ loading: true });
        this.props.getTemporaryLoginDetails(id)
            .then((result) => {
                if (result.data != null) {
                    const data = extractData(result) || {};
                    this.setState({ temporaryLoginDetails: data, error: false, loading: false });
                } else {
                    this.setState({ error: true, loading: false })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleToggleShowHidePassword = () => this.setState((prevState) => ({ showPassword: !prevState.showPassword }));

    render() {
        const { company, temporaryLoginDetails, showPassword, loading, error } = this.state;

        return (
            <Container fluid>
                <Row className="mb-3">
                    <Col md={12}>
                        <Link className="link" to={'/companies'}>Companies</Link>&nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />&nbsp;Customer Support Access
                    </Col>
                </Row>
                <Row>
                    <Col md={5} sm={12}>
                        <h2 className="block mb-4">Customer Support Access</h2>
                        {loading ? <LoadingBar /> :
                            <React.Fragment>
                                <p className="mb-4">This will allow you to generate temporary login details for this customers account. The temporary login details will be <strong>active for 30 minutes</strong> and all changes will be recorded internally.</p>
                                {error && <ErrorAlert title="There seems to be a problem" message="Unable to generate temporary details for this customers account, please try again." />}
                                <CompanyDetails company={company} />
                                <TemporaryLoginDetails temporaryLoginDetails={temporaryLoginDetails} getTemporaryLogin={() => this.getTemporaryLogin(company.id)} showPassword={showPassword} handleToggleShowHidePassword={() => this.handleToggleShowHidePassword()} />
                                <div className="mt-5">
                                    <Link to="https://portal.selazar.com/" target="_blank" rel='noopener noreferrer' onClick={(event) => { event.preventDefault(); window.open("https://portal.selazar.com/"); }}>
                                        <Button className="btn btn-primary float-right">Proceed to Customer Portal</Button>
                                    </Link>
                                    <Link to={`/companies`}>
                                        <Button variant="link" className="float-right">Cancel</Button>
                                    </Link>
                                </div>
                            </React.Fragment>
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company,
        user: state.companyReducer.companyUser,
        verifiedCompanyTimeout: state.companyReducer.verifiedCompanyTimeout
    }
}

const mapDispatchToProps = {
    getTemporaryLoginDetails: companyActions.getTemporaryLoginDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAccess)
