import { tokenisedFetch, tokenisedFetchAsync } from './Utilities';

const GET = (url) => {
    return tokenisedFetch(url);
};

const GET_ASYNC = async (url) => {
    return tokenisedFetchAsync(url);
};

const POST = (url, data) => {
    return tokenisedFetch(url, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    });
};

const POST_FILE = (url, data) => {
    return tokenisedFetch(url, {
        method: 'POST',
        body: data
    });
};

const PUT = (url, data) => {
    return tokenisedFetch(url, {
        method: 'PUT',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    });
};

const DELETE = (url) => {
    return tokenisedFetch(url, {
        method: 'DELETE'
    });
};

const extractData = (returnObject) => {
    if (returnObject) {
        if (!returnObject.error) {
            return returnObject.data;
        }
    }
    return null;
};

export {
    GET,
    GET_ASYNC,
    POST,
    POST_FILE,
    PUT,
    DELETE,
    extractData
};