import React, { Component } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { toLocalTimeString } from '../../../Utilities';
import LoadingBar from '../../common/LoadingBar';

import { companyActions } from '../../../actions/companyActions';

class CompanyOrderHistoryDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalCount: 0,
            pageIndex: 1,
            pageCount: 0,
            loading: true,
            activeKey: 'Orders in Process',
            orderHistory: {},
            orderCosts: {
                crossZonalCharge: 0,
                fuelCharge: 0,
                pickingCharge: 0,
                packagingCharge: 0,
                packingCharge: 0
            },
            adminCharge: 0,
            adminRate: 0,
            vatCharge: 0,
            vatRate: 0,
            subtotal: 0,
            totalCost: 0,
            totalWeight: 0,
            trackingUrls: []
        };
    }

    componentDidMount() {
        const order = this.props.location.state.orderHistory;
        const totalWeight = order.totalWeight.length > 0 ? `${order.totalWeight.reduce((a, b) => a + b)}kg` : "N/A"
        let trackingUrls = [];
        
        let count = order.providersAndParcelReferences.length;
        for(let i = 0; i < count; i++) {
            let papr = order.providersAndParcelReferences[i];
            this.props.getCourierTrackingUrlRoot(papr.providerId)
            .then((result) => {
                trackingUrls.push({
                    "ref": papr.parcelReference,
                    "url": result.data ? result.data + papr.parcelReference : null
                });

                count++;
            })
            .catch((error) => { 
                console.log(error)
                this.setState({
                    loading: false
                });
             });
        }

        this.setState({
            orderHistory: order,
            trackingUrls: trackingUrls,
            totalWeight: totalWeight
        })

        this.props.getOrderCosts(order.id)
            .then((result) => {
                this.setState(prevState => ({
                    orderCosts: {
                        ...prevState.orderCosts,
                        ...result.data
                    },
                    subtotal: result.data.subtotal.toFixed(2),
                    totalCost: result.data.total.toFixed(2),
                    vatCharge: result.data.vatCharge.toFixed(2),
                    adminCharge: result.data.adminCharge.toFixed(2),
                    adminRate: result.data.adminRate,
                    loading: false
                }));
            })
            .catch((error) => { 
                console.log(error);
                this.setState({
                    loading: false
                });
             });
    }

    render() {
        const { adminCharge, adminRate, loading, orderCosts, orderHistory, subtotal, totalCost, totalWeight, trackingUrls, vatCharge } = this.state;

        return (
            <Container fluid>
                <FontAwesomeIcon icon={faAngleLeft} /> <Link className="link" to={'./processing'}>
                    Return to Orders
                </Link>

                {loading ? <LoadingBar /> : <React.Fragment>

                    <h2 className="d-block mt-4 mb-4">Order Details</h2>
                    <section>
                        <h5 className="integration-section-header">Order Breakdown</h5>
                        <p className="mt-3">
                            <strong>Order Number</strong>
                            <span className="d-block">{orderHistory.customerReference}</span>
                        </p>
                        <p>
                            <strong>Internal Reference</strong>
                            <span className="d-block">{orderHistory.internalReference}</span>
                        </p>
                        <p>
                            <strong>Order Recieved</strong>
                            <span className="d-block">{toLocalTimeString(orderHistory.createdDate)}</span>
                        </p>
                        <p>
                            <strong>Customer Name</strong>
                            <span className="d-block">{orderHistory.customerOrganisationPersonName}</span>
                        </p>
                    </section>
                    <section>
                        <h5 className="integration-section-header mt-5">Parcel Details</h5>
                        <p className="mt-3">
                            <strong>Weight</strong>
                            <span className="d-block">{totalWeight}</span>
                        </p>
                        <p>
                            <strong className='d-block'>Status</strong>
                            <span className={'d-block ' + orderHistory.status.name === 'Awaiting Collection' ? 'text-status-blue' :
                                orderHistory.status.name === 'Awaiting Fulfilment' ? 'text-status-yellow' :
                                    orderHistory.status.name === 'Processing Failed' ? 'text-status-red' : 'text-status-blue'}>
                                {orderHistory.status.name}
                            </span>
                        </p>
                    </section>

                    {orderCosts !== undefined ?
                        <section>
                            <h5 className="integration-section-header mt-5">Cost Details</h5>
                            <p className="mt-3">
                                <strong>Grand Total</strong>
                                <span className="d-block">£{totalCost}</span>
                            </p>
                            <p>
                                <strong>Breakdown</strong>
                                <span className="d-block">Couriers: {orderHistory.providerNames.length > 0 ?
                                    orderHistory.providerNames.join(', ') : "N/A"}
                                </span>
                                <span className="d-block">Cross Zonal Charge: £{orderCosts.crossZonalCharge.toFixed(2)}</span>
                                <span className="d-block">Fuel Charge: £{orderCosts.fuelCharge.toFixed(2)}</span>
                                <span className="d-block">Item Picking Charge: £{orderCosts.pickingCharge.toFixed(2)}</span>
                                <span className="d-block">Packing Charge: £{orderCosts.packingCharge.toFixed(2)}</span>
                                <span className="d-block">Packaging Charge: £{orderCosts.packagingCharge.toFixed(2)}</span>
                                <span className="d-block">Service Charge: £{(orderCosts.serviceCharge + orderCosts.selazarMarkup).toFixed(2)}</span>
                                <span className="d-block">Subtotal: £{subtotal}</span>
                                <span className="d-block">Selazar charge ({adminRate}%): £{adminCharge}</span>
                                <span className="d-block">VAT: £{vatCharge}</span>
                                <span className="d-block">Grand Total: £{totalCost}</span>

                                {orderHistory.invoiceLink !== null &&
                                    <a className="d-block mt-2" href={orderHistory.invoiceLink} target='_blank' rel="noopener noreferrer">
                                        <Button className="link-button" variant="secondary">View Invoice</Button>
                                    </a>
                                }
                            </p>
                        </section> : null
                    }

                    <section>
                        <h5 className="integration-section-header mt-5">Delivery Details</h5>
                        <p className="mt-3">
                            <strong>Delivery Address</strong>
                            <span className="d-block">{orderHistory.customerAddressLine1}</span>
                            <span className="d-block">{orderHistory.customerAddressLine2}</span>
                            <span className="d-block">{orderHistory.customerAddressTown}</span>
                            <span className="d-block">{orderHistory.customerAddressCounty}</span>
                            <span className="d-block">{orderHistory.customerAddressPostcode}</span>
                        </p>
                        <p>
                            <strong>Parel References/Tracking Numbers</strong>
                            {trackingUrls.length > 0 ?
                                trackingUrls.map((pr, i) => {
                                    return pr.url ? <a
                                    key={i}
                                        className="d-block"
                                        href={pr.url}
                                        target='_blank'
                                        rel="noopener noreferrer">
                                        {pr.ref}
                                    </a> : <p className="d-block">{pr.ref}</p>
                                }) : <span className="d-block">N/A</span>}
                        </p>
                        <p>
                            <strong>Courier Providers</strong>
                            <span className="d-block">{orderHistory.providerNames.length > 0 ?
                                orderHistory.providerNames.join(', ') : "N/A"}</span>
                        </p>
                    </section>
                </React.Fragment>}
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.verifiedCompany
    }
}

const mapDispatchToProps = {
    getOrderCosts: companyActions.getOrderCosts,
    getCourierTrackingUrlRoot: companyActions.getCourierTrackingUrlRoot
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyOrderHistoryDetails)