import React, { Component } from 'react';
import { Col, Container, Image, Row, Table, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import LoadingBar from '../../common/LoadingBar';
import Pagination from '../../common/tables/Pagination';
import SelazarLinkContainer from '../../common/SelazarLinkContainer';
import UserFilter from './UserFilter';

import { userActions } from '../../../actions/userActions';
import { encodeStringForURL } from '../../../Utilities';

class Security extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            totalCount: 0,
            pageIndex: 1,
            pageCount: 1,
            users: [],
            filter: {
                name: "",
                sortBy: ""
            }
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers = () => {
        const { filter, pageIndex } = this.state;

        this.props.getFilteredCompanyUsers(this.props.company.id, filter, pageIndex)
            .then((result) => {
                this.setState({
                    users: result.users,
                    loading: false
                });
            })
            .catch(() => { });
    }

    onNext = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.getUsers);
        }
    }

    onPrevious = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.getUsers);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.getUsers);
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    onClear = () => {
        this.setState({
            users: [],
            loading: true,
            filter: {
                name: "",
                sortBy: ""
            }
        }, this.getUsers);
    }

    onSearch = (e) => {
        e.preventDefault();
        this.setState({ users: [], loading: true }, this.getUsers);
    }

    render() {
        const { loading, pageCount, pageIndex, users } = this.state
        const companyNameEncoded = encodeStringForURL(this.props.company.name.toLowerCase());

        return (
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="link" to={'/companies'}>Companies</Link>&nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />&nbsp;
                        <Link className="link" to={`/companies/${companyNameEncoded}`}>{this.props.company.name}</Link>&nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />&nbsp;
                        <Link className="link" to={`/companies/${companyNameEncoded}/settings`}>Settings</Link>&nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />&nbsp;Security
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h2 className="d-inline-block mt-4">Security</h2>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <UserFilter
                            onClear={this.onClear}
                            onSearch={this.onSearch}
                            onFilterChange={this.onFilterChange}
                            {...this.state.filter}
                            loading={this.state.loading} />
                    </Col>
                </Row>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <Row className="mt-4">
                            <Col md={12}>
                                <Card>
                                    <div className="table-responsive">
                                        <Table striped bordered size="sm">
                                            <thead>
                                                <tr>
                                                    <th>User Name</th>
                                                    <th>User Email</th>
                                                    <th>Date Activated</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.length ? users.map((u) => {
                                                    return (
                                                        <tr key={u.id}>
                                                            <td>{`${u.forename} ${u.surname}`}</td>
                                                            <td>{u.email}</td>
                                                            <td>{u.createdDateDisplay}</td>
                                                            <td>
                                                                {u.active ? <span className="text-status-green">Active</span>
                                                                    : <span className="text-status-red">Deactivated</span>}
                                                            </td>
                                                            <td className="text-center py-2">
                                                                <SelazarLinkContainer
                                                                    to={{
                                                                        pathname: `/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/settings/security/${u.id}`,
                                                                        state: { user: u }
                                                                    }} exact>
                                                                    <Button className="link-button" variant="secondary" size="sm">Change Password</Button>
                                                                </SelazarLinkContainer>
                                                            </td>
                                                        </tr>);
                                                }) :
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <Image src='Images/search-result.png' className="mx-auto d-block" width="100px" />
                                                            <p className="text-center">
                                                                No users available.<br />
                                                            </p>
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                        <Pagination
                                            onNext={this.onNext}
                                            onPrevious={this.onPrevious}
                                            onPageSelect={this.onPageSelect}
                                            pageIndex={pageIndex}
                                            pageCount={pageCount} />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>}
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.verifiedCompany
    }
}

const mapDispatchToProps = {
    getFilteredCompanyUsers: userActions.getFilteredCompanyUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(Security)