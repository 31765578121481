import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, company, user, ...rest }) => (
    <Route {...company}{...user}{...rest}
        render={(props) => {
            if (company.isAuth) {
                return <Component {...props} />
            } else {
                return <Redirect to={{ pathname: "/companies", state: { from: props.location } }} />
            }
        }
        }
    />
);

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company,
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(PrivateRoute)