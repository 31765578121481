import React, { Component } from 'react';
import { Button, Card, Col, Container, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import Confirm from '../../common/Confirm';
import FormValidator from '../../common/FormValidator';

import { companyActions } from '../../../actions/companyActions';
import { encodeStringForURL } from '../../../Utilities';

class CompanyProfileEdit extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'addressLine1',
                method: 'isEmpty',
                validWhen: false,
                message: 'Address Line 1 is required'
            },
            {
                field: 'town',
                method: 'isEmpty',
                validWhen: false,
                message: 'Town is required'
            },
            {
                field: 'country',
                method: 'isEmpty',
                validWhen: false,
                message: 'Country is required'
            },
            {
                field: 'county',
                method: 'isEmpty',
                validWhen: false,
                message: 'County is required'
            },
            {
                field: 'postcode',
                method: 'isEmpty',
                validWhen: false,
                message: 'Postcode is required'
            }
        ]);

        this.state = {
            mode: '',
            dirty: false,
            errorMessage: '',
            email: '',
            emailUpdated: false,
            name: '',
            nameUpdated: false,
            billing: {
                addressLine1: '',
                addressLine2: '',
                town: '',
                country: '',
                county: '',
                postcode: ''
            },
            billingUpdated: false,
            initEmail: '',
            initName: '',
            showConfirm: false,
            validation: this.validator.valid()
        }
    }

    componentDidMount() {
        const mode = this.props.location.state.mode;
        const email = this.props.email;
        const companyName = this.props.company.name;
        const billingAddress = this.props.companyBillingAddress

        this.setState(prevState => ({
            ...prevState,
            mode: mode,
            initEmail: email,
            initName: companyName,
            name: companyName,
            email: email,
            billing: billingAddress
        }));
    }

    handleChange = (e) => {
        let { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            ...{ [name]: value }
        }));
    }

    handleBillingChange = (e) => {
        const { name, value } = e.target;
        const newBilling = { ...this.state.billing };
        newBilling[name] = value;

        this.setState(prevState => ({
            ...prevState,
            billing: newBilling,
            dirty: true
        }));
    }

    editCompanyName = () => {
        if (this.state.name !== '') {
            this.props.updateCompanyName(this.props.company.id, this.state.name, this.props.email)
                .then(() => {
                    this.setState({ nameUpdated: true });
                })
                .catch((error) => {
                    this.setState({ errorMessage: error.message });
                    console.log(error);
                })
        } else {
            this.setState({ errorMessage: 'Please enter a name' });
        }
    }

    editEmail = () => {
        if (this.state.email !== '') {
            this.props.updateCompanyEmail(this.props.company.id, this.props.email, this.state.email)
                .then((result) => {
                    this.setState({ emailUpdated: true });
                })
                .catch((error) => {
                    this.setState({ errorMessage: error.message });
                    console.log(error);
                })
        } else {
            this.setState({ errorMessage: 'Please enter an email' });
        }
    }

    editBilling = (e) => {
        e.preventDefault();

        let form = this.state.billing;
        const validation = this.validator.validate(form);
        this.setState({ validation: validation });

        if (validation.isValid) {
            this.props.updateBillingAddress(
                this.props.company.id,
                this.props.email,
                this.state.billing.addressLine1,
                this.state.billing.addressLine2,
                this.state.billing.town,
                this.state.billing.country,
                this.state.billing.county,
                this.state.billing.postcode
            ).then(() => {
                this.setState({ billingUpdated: true });
            })
                .catch((error) => { console.log(error); })
        }
    }

    cancel = () => {
        if (this.state.dirty) {
            this.setState({ showConfirm: true });
        } else {
            this.props.history.push(`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/profile`);
        }
    }

    handleToggleShowHide = () => {
        this.setState({ showConfirm: false });
    }

    handleToggleAction = () => {
        this.props.history.push(`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/profile`);
    }

    render() {
        let { showConfirm, mode, errorMessage, validation, nameUpdated, emailUpdated, billingUpdated, name, email, billing } = this.state;

        const editName = () => {
            return <Row>
                <Col md={12}>
                    <h2 className="mb-3">Edit Name</h2>
                </Col>

                {!nameUpdated ?
                    <Col md={6}>
                        <Form>
                            <fieldset>
                                <FormGroup>
                                    <FormLabel htmlFor="name"><strong>Name</strong></FormLabel>
                                    <FormControl id="name" type="text" name="name" value={name} onChange={this.handleChange} />
                                    <span className="text-danger">{errorMessage}</span>
                                </FormGroup>
                            </fieldset>
                            <Button className="btn btn-primary float-right" onClick={this.editCompanyName}>Save Name</Button>
                            <Link to={`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/profile`}>
                                <Button variant="link" className="float-right">Cancel</Button>
                            </Link>
                        </Form>
                    </Col>
                    :
                    <Col md={6}>
                        <React.Fragment>
                            <Card className="card-success mb-2">
                                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />Success</Card.Title>
                                <Card.Body>
                                    You have successfully changed the company name from {this.state.initName} to {this.state.name}
                                </Card.Body>
                            </Card>
                            <Link to={`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/profile`}>
                                <Button className="btn btn-primary float-right mt-3">Return to Company Profile</Button></Link>
                        </React.Fragment>
                    </Col>}
            </Row>
        }

        const editEmail = () => {
            return <Row>
                <Col md={12}>
                    <h2 className="mb-3">Edit Email</h2>
                </Col>

                {!emailUpdated ?
                    <Col md={6}>
                        <Form>
                            <fieldset>
                                <FormGroup>
                                    <FormLabel htmlFor="email"><strong>Email</strong></FormLabel>
                                    <FormControl id="email" type="email" name="email" value={email} onChange={this.handleChange} />
                                    <span className="text-danger">{errorMessage}</span>
                                </FormGroup>
                            </fieldset>
                            <Button className="btn btn-primary float-right" onClick={this.editEmail}>Save Email</Button>
                            <Link to={`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/profile`}>
                                <Button variant="link" className="float-right">Cancel</Button>
                            </Link>
                        </Form>
                    </Col>
                    :
                    <Col md={6}>
                        <React.Fragment>
                            <Card className="card-success mb-2">
                                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />Success</Card.Title>
                                <Card.Body>
                                    You have successfully changed the email address from {this.state.initEmail} to {this.state.email}
                                </Card.Body>
                            </Card>
                            <Link to={`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/profile`}>
                                <Button className="btn btn-primary float-right mt-3">Return to Company Profile</Button></Link>
                        </React.Fragment>
                    </Col>}
            </Row>
        }

        const editBillingAddress = () => {
            return <Row>
                <Col md={12}>
                    <h2 className="mb-3">Edit Billing Address</h2>
                </Col>

                {showConfirm && <Confirm title="Cancel Edit Billing Address"
                    text="You have unsaved changes to the billing address, are you sure you want to cancel?"
                    buttonText="Cancel Editing"
                    buttonVariant="danger"
                    cancelText="Continue editing address"
                    cancelButtonVariant="link"
                    handleConfirmClose={this.handleToggleShowHide}
                    handleConfirmAction={this.handleToggleAction}
                    handleClose={() => { this.setState({ showConfirm: false }) }} />}

                {!billingUpdated ?
                    <Col md={6}>
                        <Form onSubmit={this.editBilling}>
                            <fieldset>
                                <FormGroup>
                                    <FormLabel htmlFor="addressLine1"><strong>Address Line 1</strong></FormLabel>
                                    <FormControl id="addressLine1" type="text" name="addressLine1" value={billing.addressLine1} onChange={this.handleBillingChange} />
                                    <span className="text-danger">{validation.addressLine1.message}</span>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="addressLine2"><strong>Address Line 2</strong><small><i> - optional</i></small></FormLabel>
                                    <FormControl id="addressLine2" type="text" name="addressLine2" value={billing.addressLine2} onChange={this.handleBillingChange} />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="town"><strong>Town</strong></FormLabel>
                                    <FormControl id="town" type="text" name="town" value={billing.town} onChange={this.handleBillingChange} />
                                    <span className="text-danger">{validation.town.message}</span>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="country"><strong>Country</strong></FormLabel>
                                    <FormControl id="country" type="text" name="country" value={billing.country} onChange={this.handleBillingChange} />
                                    <span className="text-danger">{validation.country.message}</span>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="county"><strong>County</strong></FormLabel>
                                    <FormControl id="county" type="text" name="county" value={billing.county} onChange={this.handleBillingChange} />
                                    <span className="text-danger">{validation.county.message}</span>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="postcode"><strong>Postcode</strong></FormLabel>
                                    <FormControl id="postcode" type="text" name="postcode" value={billing.postcode} onChange={this.handleBillingChange} />
                                    <span className="text-danger">{validation.postcode.message}</span>
                                </FormGroup>
                            </fieldset>
                            <Button className="btn btn-primary float-right" type="submit">Save Billing Address</Button>
                            <Button variant="link" className="float-right" onClick={this.cancel}>Cancel</Button>
                        </Form>
                    </Col>
                    :
                    <Col md={6}>
                        <React.Fragment>
                            <Card className="card-success mb-2">
                                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />Success</Card.Title>
                                <Card.Body>
                                    You have successfully changed the billing address.
                                </Card.Body>
                            </Card>
                            <Link to='/companies/name/profile'>
                                <Button className="btn btn-primary float-right mt-3">Return to Company Profile</Button></Link>
                        </React.Fragment>
                    </Col>}
            </Row >
        }

        return (
            <Container fluid>
                {mode === 'name' && editName()}
                {mode === 'email' && editEmail()}
                {mode === 'billing' && editBillingAddress()}
            </Container >
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.verifiedCompany,
        companyBillingAddress: state.companyReducer.companyBillingAddress,
        email: state.companyReducer.companyUser.email,
        forename: state.companyReducer.forename,
        surname: state.companyReducer.surname
    }
}

const mapDispatchToProps = {
    updateCompanyEmail: companyActions.updateCompanyEmail,
    updateCompanyName: companyActions.updateCompanyName,
    updateBillingAddress: companyActions.updateBillingAddress
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileEdit)