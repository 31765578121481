import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import './Confirm.scss';

const Confirm = ({ title, text, buttonText, buttonVariant = "primary", cancelText, cancelButtonVariant = "link", handleConfirmClose, handleConfirmAction, handleClose }) =>
    <Modal centered show onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{text}</p>
        </Modal.Body>
        <Modal.Footer className="pt-0">
            <Button variant={cancelButtonVariant} onClick={handleConfirmClose}>{cancelText}</Button>
            <Button variant={buttonVariant} onClick={handleConfirmAction}>{buttonText}</Button>
        </Modal.Footer>
    </Modal>

export default Confirm;