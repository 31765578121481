import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';

import './Actions.scss';

class Actions extends Component {
    render() {

        const { title, actions = [] } = this.props

        return (
            <div className="action-container">
                <Dropdown className="table-dropdown-action">
                    <Dropdown.Toggle data-boundary="viewport" className="dropdown-toggle-action">...</Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Header>{title}</Dropdown.Header>
                        <Dropdown.Divider></Dropdown.Divider>
                        {actions.length && actions.map((a, i) => a && <Dropdown.Item key={`action-${i}`}>{a}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }
}

export default Actions;