import React, { Component, Fragment } from 'react';
import LoadingBar from "../common/LoadingBar";
import { Col, Row, Container, Card } from 'react-bootstrap';
import FinanceReportsFilter from './FinanceReportsFilter';
import { reportActions } from '../../actions/reportActions';
import { connect } from 'react-redux';
import { ErrorAlert, SuccessAlert } from '../common/Alerts';
import Endpoints from '../common/Endpoints';
import jwt from 'jwt-decode';

class FinanceReportsMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            types: [{ name: 'Selazar breakdown report', value: 'SELAZAR_BREAKDOWN_REPORT' },
            { name: 'Selazar Xero report', value: 'SELAZAR_XERO_REPORT' },
            { name: 'RR Breakdown Report', value: 'RR_BREAKDOWN_REPORT' },
            { name: 'RR Xero report', value: 'RR_XERO_REPORT' }],
            loading: false,
            filter: { reportType: "", dateFrom: "", dateTo: "", orderDescending: true },
            showAlert: false
        }
    }

    onExecute = (event) => {
        event.preventDefault();
        const { filter } = this.state;
        switch (filter.reportType) {
            case 'SELAZAR_BREAKDOWN_REPORT':
                this.setState({ loading: true });
                this.props.getInvoiceBreakdownReport(filter)
                    .then((result) => {
                        this.setState(prevState => ({
                            ...prevState,
                            filter: {
                                ...prevState.filter
                            },
                            loading: false,
                            error: false,
                            success: true,
                            title: "Success",
                            message: result.message,
                            showAlert: true
                        }));
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                            error: true,
                            success: false,
                            title: "Error",
                            message: error.message,
                            showAlert: true
                        });
                    });
                break;
            case 'SELAZAR_XERO_REPORT':
                console.log('To implement SELAZAR_XERO_REPORT');
                break;
            case 'RR_BREAKDOWN_REPORT':
                const token = JSON.parse(localStorage.getItem("user")).token;
                const userData = jwt(token);
                const email = userData.email; 

                fetch(Endpoints.INVOICE_REPORTS.GET.RRINVOICE_BREAKDOWN, {
                    method: 'post',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({
                        "DateFrom": filter.dateFrom,
                        "DateTo": filter.dateTo,
                        "ReportType": "RR_BREAKDOWN_REPORT",
                        "UserInSession": email
                    })
                })
                    .then(response => response.json())
                    .then(data => {
                        this.setState({
                            loading: false,
                            error: false,
                            success: true,
                            title: "Success",
                            message: "Success",
                            showAlert: true
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                            error: true,
                            success: false,
                            title: "Error",
                            message: error.message,
                            showAlert: true
                        });
                    });

                break;
            case 'RR_XERO_REPORT':
                console.log('To implement RR_XERO_REPORT');
                break;
        }

    }

    onFilterChange = (e) => {
        let { name, value, type } = e.target;
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }
    onClear = () => {
        this.setState({
            filter: {
                reportType: "",
                dateFrom: "",
                dateTo: "",
                orderDescending: true
            },
            showAlert: false
        });
    }

    render() {
        const { loading, types, filter, message, title, error, showAlert } = this.state;
        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Container fluid>
                        <Row className="my-4">
                            <Col>
                                <FinanceReportsFilter types={types} onExecute={this.onExecute} onFilterChange={this.onFilterChange} onClear={this.onClear} {...filter} />
                            </Col>
                        </Row>
                        <Row>
                            {!error && showAlert ? <SuccessAlert title={title} message={message} /> : null}
                            {error && showAlert ? <ErrorAlert title={title} message={message} /> : null}
                        </Row>
                    </Container>
                </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company,
        companyUser: state.companyReducer.companyUser,
        user: state.userReducer.user
    }
}

const mapDispatchToProps = {
    getInvoiceBreakdownReport: reportActions.getInvoiceSelazarBreakdown
}

export default connect(mapStateToProps, mapDispatchToProps)(FinanceReportsMain)