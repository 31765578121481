import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history'

import App from './App';
import rootReducer from './reducers/rootReducer';
import registerServiceWorker from './registerServiceWorker';

import './index.scss';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const config = {
    key: 'selazar-store-root',
    storage,
    transforms: []
};

let middleware = [];
if (process.env.NODE_ENV === 'development') {
    middleware = [...middleware, thunk, createLogger()];
} else {
    middleware = [...middleware, thunk];
}

const selazarPersistReducer = persistReducer(config, rootReducer);
const enhancers = [applyMiddleware(...middleware)];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = { enhancers };

const store = createStore(
    selazarPersistReducer,
    undefined,
    composeEnhancers(...enhancers)
);

const persistor = persistStore(store, persistConfig, () => { });
const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Router basename={baseUrl} history={history}>
                <Route component={App} />
            </Router>
        </PersistGate>
    </Provider>,
    rootElement);

registerServiceWorker();