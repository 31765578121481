import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { userActions } from './actions/userActions';
import { companyActions } from './actions/companyActions';

import { Layout } from './components/Layout';
import { getUser, hasTokenExpired, useInterval } from './Utilities';
import PrivateRoute from './PrivateRoute';
import Login from './components/Login';
import ForgotPassword from './components/account/password/ForgotPassword';
import ResetPassword from './components/account/password/ResetPassword';
import ResetResult from './components/account/password/ResetResult';

import NotFound from './components/common/NotFound';
import Dashboard from './components/Dashboard';

import CompaniesForCredit from './components/creditActions/CompaniesForCredit';
import CreditActions from './components/creditActions/CreditActions';
import AdditionalCosts from './components/creditActions/AdditionalCosts';
import CreditNotes from './components/creditActions/CreditNotes';
import CreateCreditNote from './components/creditActions/CreateCreditNote';
import ViewCreditNotes from './components/creditActions/ViewCreditNotes';
import CreditNoteDetails from './components/creditActions/CreditNoteDetails';

import Companies from './components/company/Companies';
import CompanyVerificationStepOne from './components/company/verification/CompanyVerificationStepOne';
import CompanyVerificationStepTwo from './components/company/verification/CompanyVerificationStepTwo';
import CompanyVerificationStepThree from './components/company/verification/CompanyVerificationStepThree';

import CompanyDashboard from './components/company/CompanyDashboard';
import CompanyProfile from './components/company/profile/CompanyProfile';
import CompanyProfileEdit from './components/company/profile/CompanyProfileEdit';
import CompanyOrderHistory from './components/company/orderHistory/CompanyOrderHistory';
import CompanyCompletedOrderHistory from './components/company/orderHistory/CompanyCompletedOrderHistory';
import CompanyFailedOrderHistory from './components/company/orderHistory/CompanyFailedOrderHistory';
import CompanyOrderHistoryDetails from './components/company/orderHistory/CompanyOrderHistoryDetails';
import CompanyAccess from './components/company/access/CompanyAccess';
import Invoicing from './components/company/invoices/Invoicing';
import Invoices from './components/company/invoices/Invoices';
import Settings from './components/company/settings/Settings';
import Security from './components/company/settings/Security';
import ChangePassword from './components/company/settings/ChangePassword';
import FinanceReportsMain from './components/reports/FinanceReportsMain';
import './App.css';

// 15 seconds
const CHECK_TOKEN_INTERVAL = 15000;
const unAuthenticatedRoutes = "account";

const App = () => {

  const isLoggedIn = () => {
    const user = getUser();
    return user ? true : false;
  }

  //#region State

  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const [tokenExpired, setTokenExpired] = useState(false);

  //#endregion

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  //#region Hooks

  // run on first render + anytime loggedIn changes
  useEffect(() => {
    if(!loggedIn) {
        if(location.pathname.split("/")[1] !== unAuthenticatedRoutes) simulateLogout();
    }
  }, [loggedIn])


  // run every 15 seconds
  const checkToken = useCallback(() => {
    const loggedIn = isLoggedIn();
    setLoggedIn(loggedIn);

    if(loggedIn){
      const isTokenExpired = hasTokenExpired();
      setTokenExpired(isTokenExpired);
      setLoggedIn(!isTokenExpired);
    }
  }, [loggedIn]);

  useInterval(checkToken, CHECK_TOKEN_INTERVAL);

  //#endregion

  //#region Functions

  const onLogin = (data) => {
    if(data.token){
      localStorage.setItem('user', JSON.stringify(data));
      setLoggedIn(true);
      setTokenExpired(false);
      history.push("/");
    }
  }

  const onLogout = () => {
    setLoggedIn(false);
  }

  // if token has expired
  const simulateLogout = () => {
    dispatch(companyActions.resetCompany())
    .then(() => dispatch(userActions.logout()))
    .then(() => {
      setLoggedIn(false);
      history.push("/login");
      localStorage.clear();
    });
  }

  //#endregion

  //#region Render

  if(loggedIn)
    return (
      <>
      <Layout onLogout={onLogout} history={history}>
        <Switch>
          <Route exact path='/' component={Dashboard} />
          <Route exact path='/companies' component={Companies} />
          <Route exact path='/companies/:name/verify/step1' component={CompanyVerificationStepOne} />
          <Route exact path='/companies/:name/verify/step2' component={CompanyVerificationStepTwo} />
          <Route exact path='/companies/:name/verify/step3' component={CompanyVerificationStepThree} />

          <Route exact path='/companiesforcredit' component={CompaniesForCredit} />
          <Route exact path='/companiesforcredit/:name/creditactions' component={CreditActions} />
          <Route exact path='/companiesforcredit/:name/creditactions/additionalcosts' component={AdditionalCosts} />
          <Route exact path='/companiesforcredit/:name/creditactions/creditnotes' component={CreditNotes} />
          <Route exact path='/companiesforcredit/:name/creditactions/creditnotes/create' component={CreateCreditNote} />
          <Route exact path='/companiesforcredit/:name/creditactions/creditnotes/view' component={ViewCreditNotes} />
          <Route exact path='/companiesforcredit/:name/creditactions/creditnotes/view/:id' component={CreditNoteDetails} />

          <PrivateRoute exact path='/companies/:name' component={CompanyDashboard} />
          <PrivateRoute exact path='/companies/:name/profile' component={CompanyProfile} />
          <PrivateRoute exact path='/companies/:name/profile/edit/:detail' component={CompanyProfileEdit} />
          <PrivateRoute exact path='/companies/:name/orderhistory/processing' component={CompanyOrderHistory} />
          <PrivateRoute exact path='/companies/:name/orderhistory/completed' component={CompanyCompletedOrderHistory} />
          <PrivateRoute exact path='/companies/:name/orderhistory/failed' component={CompanyFailedOrderHistory} />
          <PrivateRoute exact path='/companies/:name/orderhistory/:id' component={CompanyOrderHistoryDetails} />
          <PrivateRoute exact path='/companies/:name/invoicing' component={Invoicing} />
          <PrivateRoute exact path='/companies/:name/invoicing/invoices' component={Invoices} />
          <PrivateRoute exact path='/companies/:name/settings' component={Settings} />
          <PrivateRoute exact path='/companies/:name/settings/security' component={Security} />
          <PrivateRoute exact path='/companies/:name/settings/security/:id' component={ChangePassword} />
          <Route exact path='/companies/:name/access' component={CompanyAccess} />

          <Route exact path='/financereportsmain' component={FinanceReportsMain} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
      </>
    )
    return (
      <>
      <React.Fragment>
        <Switch>
          <Route exact path='/login' render={() => (<Login onLogin={onLogin} tokenExpired={tokenExpired} />)} />
          <Route exact path='/account/password/forgot' component={ForgotPassword} />
          <Route exact path='/account/password/result' component={ResetResult} />
          <Route exact path='/account/password/reset/:id' component={ResetPassword} />
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      </React.Fragment>
      </>
    )

  //#endregion
}

export default App;