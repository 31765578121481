import React, { Component } from 'react';
import { Button, FormGroup, FormControl } from 'react-bootstrap';

class VerificationCodeForm extends Component {
    render() {
        return (
            <React.Fragment>
                <FormGroup>
                    <FormControl required name="inputOne" type="text" maxLength="1" className='verification-input' onChange={this.props.handleInput} />
                    <FormControl required name="inputTwo" type="text" maxLength="1" className='verification-input' onChange={this.props.handleInput} />
                    <FormControl required name="inputThree" type="text" maxLength="1" className='verification-input' onChange={this.props.handleInput} />
                    <FormControl required name="inputFour" type="text" maxLength="1" className='verification-input' onChange={this.props.handleInput} />
                </FormGroup>
                <Button name="verify" className="float-left mb-2" onClick={this.props.verifyCode}>Verify code</Button>
            </React.Fragment>
        );
    }
}

export default VerificationCodeForm