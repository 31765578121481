import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavMenu } from './NavMenu';
import Header from './Header';

import './Layout.scss';

export class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <Header onLogout={this.props.onLogout} history={this.props.history}/>
        <div className="content-wrapper">
          <NavMenu />
          <Container fluid>
            <Row className="p-4">
              <Col lg={12}>
                {this.props.children}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}