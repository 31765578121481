import { GET_ASYNC } from "../../Consumer";
import Endpoints from "../../components/common/Endpoints";

export const FetchCategories = async (setCategories, setErrorMessages) => {
    try {
        const response = await GET_ASYNC(Endpoints.CREDIT.GET.RETRIEVE_CATEGORIES)
        if (response?.ok) {
            const result = await response.json();
            if (result.error) {
                result.data !== null
                    ? setErrorMessages(result.data)
                    : setErrorMessages(result.message);
            } else {
                const categories = result.data ? result.data : [];
                setCategories(categories);
            }
        }
        else setErrorMessages(response.status);
    } catch (error) {
        console.log(error);
        setErrorMessages(["There has been an issue retrieving categories."])
    }
};