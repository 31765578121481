import React from 'react';
import { Col, Form, FormControl, FormGroup, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class CompanyFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            types: [],
        };
    }

    render() {
        const { name, onSearch, onFilterChange } = this.props;

        return (
            <Form onSubmit={onSearch}>
                <FormGroup>
                    <Row>
                        <Col md={3}>
                            <InputGroup>
                                <FormControl id="nameField" className="input-filter" type="text" name="name" placeholder="Search by name" value={name} onChange={onFilterChange} />
                                <InputGroup.Append>
                                    <InputGroup.Text className="input-filter-icon">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>
                </FormGroup>
            </Form>
        );
    }
}

export default CompanyFilter;