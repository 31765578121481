import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { toLocalTimeString, encodeStringForURL } from '../../Utilities';

class CreditNoteDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creditNote: {}
        };
    }

    componentDidMount() {
        const creditNote = this.props.location.state.creditNote;
        const status = this.props.location.state.status;

        this.setState({ creditNote: creditNote, status: status });
    }

    getCreditNoteStatus = (amount, amountRemaining) => {
        const status = amount === amountRemaining ? "NOT_REDEEMED" :
            amountRemaining === 0 ? "REDEEMED" : "PART_REDEEMED";

        switch (status) {
            case "NOT_REDEEMED": return <span className="text-status-blue">Not Redeemed</span>
            case "PART_REDEEMED": return <span className="text-status-yellow">Part Redeemed</span>
            case "REDEEMED": return <span className="text-status-green">Redeemed</span>
            default: return null
        }
    }

    render() {
        const { creditNote } = this.state;
        const currencySymbol = this.props.company.companyWarehouses.find(e => e.warehouse.id === creditNote.warehouseID)?.warehouse.warehouseCurrency.currency.symbol;

        return (
            <Container fluid>
                <Row>
                    <Col md={6}>
                        <FontAwesomeIcon icon={faAngleLeft} /> <Link className="link" to={`/companiesforcredit/${encodeStringForURL(this.props.company.name.toLowerCase())}/creditactions/creditnotes/view`}>
                            Return to Credit Notes
                        </Link>

                        <h2 className="d-block mt-4 mb-4">Credit Note Details</h2>
                        <section>
                            <h5 className="integration-section-header">Company Details</h5>
                            <p className="mt-3">
                                <strong>Company Name</strong>
                                <span className="d-block">{creditNote.companyName}</span>
                            </p>
                            <p>
                                <strong>Company ID</strong>
                                <span className="d-block">{creditNote.companyID}</span>
                            </p>
                        </section>
                        <section>
                            <h5 className="integration-section-header mt-5">Credit Note Information</h5>
                            <p className="mt-3">
                                <strong>Credit Amount Issued ({currencySymbol})</strong>
                                <span className="d-block">{currencySymbol}{parseFloat(creditNote.amount).toFixed(2)}</span>
                            </p>
                            {creditNote.postVatAmount ?
                            <p className="mt-3">
                                <strong>Credit Amount Issued ({creditNote.taxRateName} {creditNote.taxRate}) ({currencySymbol})</strong>
                                <span className="d-block">
                                    {currencySymbol}{parseFloat(creditNote.postVatAmount).toFixed(2)}                                
                                </span>
                            </p> : null
                            }
                            <p>
                                <strong>Date and Time of Issue</strong>
                                <span className="d-block">{toLocalTimeString(creditNote.createdDate)}</span>
                            </p>
                            <p className="mt-3">
                                <strong>Customer Agent</strong>
                                <span className="d-block">{creditNote.customerServiceAgentName}</span>
                            </p>
                            <p className="mt-3">
                                <strong>Reason for Credit</strong>
                                <span className="d-block text-break">{creditNote.notes}</span>
                            </p>
                            <p className="mt-3">
                                <strong>Order Number</strong>
                                <span className="d-block text-break">{creditNote.orderNumber}</span>
                            </p>
                        </section>
                        <section>
                            <h5 className="integration-section-header mt-5">Credit Usage</h5>
                            <p>
                                <strong className='d-block'>Status</strong>
                                {this.getCreditNoteStatus((creditNote.postVatAmount || creditNote.amount), creditNote.amountRemaining)}
                            </p>
                            <p>
                                <strong>Credit Remaining ({currencySymbol})</strong>
                                <span className="d-block">{currencySymbol}{parseFloat(creditNote.amountRemaining).toFixed(2)}</span>
                            </p>
                        </section>
                    </Col>
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company
    }
}

export default connect(mapStateToProps, null)(CreditNoteDetails)