import React, { Component } from 'react';
import { Container, Image } from 'react-bootstrap';
import { connect } from 'react-redux';

import { userActions } from '../actions/userActions';
import { companyActions } from '../actions/companyActions';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedInUserName: ''
        }
    }

    componentDidMount() {
        this.props.setCurrentVerificationStep(0);

        if (this.props.user.forename === undefined) {
            this.props.getUser(this.props.user.id)
                .then((result) => {
                    this.setState({
                        loggedInUserName: result.forename
                    });
                })
                .catch((error) => { console.log(error); });
        } else {
            this.setState({ loggedInUserName: this.props.user.forename });
        }
    }

    render() {
        const { loggedInUserName } = this.state;

        return (
            <Container fluid>
                <h2>Welcome back {loggedInUserName}</h2>
                <span>Here we have gathered all the information that you need to help you do your job.</span>
                <Image src='Images/welcome-login.svg' width="124px" className="d-block mt-4" />
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.user
    }
}

const mapDispatchToProps = {
    getUser: userActions.getUser,
    resetCompany: companyActions.resetCompany,
    setCurrentVerificationStep: companyActions.setCurrentVerificationStep
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)