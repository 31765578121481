import React from 'react';
import { Button, Col, FormLabel, Form, FormGroup, Row, FormControl, InputGroup, display } from 'react-bootstrap';

const FinanceReportsFilter = ({ types, reportType, dateFrom, dateTo, onExecute, onFilterChange, onClear}) => {

    return (
    <Form onSubmit={onExecute}>
        <FormGroup>
            <Row>
                <Col md={4}>
                    <h3>Reports</h3>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <p>Use this report to export invoice data by date range.</p>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormLabel htmlFor="reportDateRange">Report Date Range</FormLabel>
                    <InputGroup>

                        <FormControl id="dateFromField" type="date" name="dateFrom" value={dateFrom} onChange={onFilterChange} />
                        <FormControl id="dateToField" type="date" name="dateTo" value={dateTo} onChange={onFilterChange} />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormLabel htmlFor="reportType">Report Type</FormLabel>
                    <FormControl id="reportType" name="reportType" as="select" value={reportType} onChange={onFilterChange}>
                        <option key="" value="">Please Select...</option>;
                        {types.map(type => (<option key={type.value} value={type.value}>{type.name}</option>))}
                    </FormControl>
                </Col>
            </Row>
        </FormGroup>
        <FormGroup>
            <Row>
                <Col md={4}>
                    <Button id="buttonGenerateReport" variant="primary" disabled={!(reportType && dateFrom && dateTo)} type="submit">Run Report</Button>
                    <Button id="buttonClear" variant="link" className="float-right" onClick={onClear}>Clear filter</Button>
                </Col>
            </Row>
        </FormGroup>
    </Form>
    )
}

export default FinanceReportsFilter;