import React, { Component } from 'react';
import { Col, Container, Image, Row, Table, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import LoadingBar from '../common/LoadingBar';
import Pagination from '../common/tables/Pagination';
import CreditNoteFilter from './CreditNoteFilter';
import { toLocalTimeString, encodeStringForURL } from '../../Utilities';

import { companyActions } from '../../actions/companyActions';

class ViewCreditNotes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            totalCount: 0,
            pageIndex: 1,
            pageCount: 0,
            creditNotes: [],
            filter: {
                orderNumber: "",
                dateFrom: "",
                dateTo: "",
                orderDescending: true
            }
        }
    }

    componentDidMount() {
        const { pageIndex, filter } = this.state
        this.getCreditNotes(this.props.company.id, filter, pageIndex);
    }

    getCreditNotes = (companyId, filter, pageIndex) => {
        this.props.getCreditNotes(companyId, filter, pageIndex)
            .then((result) => {
                if (!result.error) {
                    this.setState({
                        creditNotes: result.data.creditNotes,
                        totalCount: result.data.total,
                        pageCount: result.data.pageCount,
                        loading: false
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    getCreditNoteStatus = (amount, amountRemaining) => {
        const status = amount === amountRemaining ? "NOT_REDEEMED" :
            amountRemaining === 0 ? "REDEEMED" : "PART_REDEEMED";

        switch (status) {
            case "NOT_REDEEMED": return <span className="text-status-blue">Not Redeemed</span>
            case "PART_REDEEMED": return <span className="text-status-yellow">Part Redeemed</span>
            case "REDEEMED": return <span className="text-status-green">Redeemed</span>
            default: return <span className="text-status-grey">{status}</span>
        }
    }

    onNext = () => {
        const { pageIndex, pageCount, filter } = this.state;
        const newIndex = parseInt(pageIndex) + 1;

        if (pageIndex < pageCount) {
            this.setState({ pageIndex: newIndex, loading: true }, this.getCreditNotes(this.props.company.id, filter, newIndex));
        }
    }

    onPrevious = () => {
        const { pageIndex, filter } = this.state;
        const newIndex = parseInt(pageIndex) - 1;

        if (pageIndex > 1) {
            this.setState({ pageIndex: newIndex, loading: true }, this.getCreditNotes(this.props.company.id, filter, newIndex));
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        const { filter } = this.state;
        this.setState({ pageIndex: value, loading: true }, this.getCreditNotes(this.props.company.id, filter, value));
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value },
            }
        }));
    }

    setLoading = (loading) => {
        this.setState({ loading: loading });
    }

    onClear = () => {
        const filter = {
            orderNumber: "",
            orderDescending: true,
            dateFrom: "",
            dateTo: ""
        };

        this.setState({
            creditNotes: [],
            filter: filter
        }, this.getCreditNotes(this.props.company.id, filter, 1));
    }

    onSearch = (e) => {
        e.preventDefault();
        const { pageIndex, filter } = this.state;

        this.setState({
            creditNotes: [],
            loading: true
        }, this.getCreditNotes(this.props.company.id, filter, pageIndex));
    }

    render() {
        const { creditNotes, loading, pageCount, pageIndex } = this.state;
        const companyNameEncoded = encodeStringForURL(this.props.company.name.toLowerCase());

        return (
            <Container fluid>
                <Link className="link" to={'/companiesforcredit'}>Credit Actions Company Lookup</Link>&nbsp;
                <FontAwesomeIcon icon={faAngleRight} />&nbsp;
                <Link className="link" to={`/companiesforcredit/${companyNameEncoded}/creditactions`}>Credit Actions</Link>&nbsp;
                <FontAwesomeIcon icon={faAngleRight} />&nbsp;
                <Link className="link" to={`/companiesforcredit/${companyNameEncoded}/creditactions/creditnotes`}>{this.props.company.name} Credit Notes</Link>&nbsp;
                <FontAwesomeIcon icon={faAngleRight} /> View Credit Notes

                <Row>
                    <Col md={12}>
                        <h2 className="d-inline-block mt-4">Credit Notes</h2>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <CreditNoteFilter
                            onSearch={this.onSearch}
                            onFilterChange={this.onFilterChange}
                            onClear={this.onClear}
                            {...this.state.filter}
                            onLoading={this.setLoading} />
                    </Col>
                </Row>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <Row className="mt-4">
                            <Col md={12}>
                                <Card>
                                    <div className="table-responsive">
                                        <Table striped bordered size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Order Number</th>
                                                    <th>Currency</th>
                                                    <th>Amount</th>
                                                    <th>Customer Agent</th>
                                                    <th>Date Created</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {creditNotes.length > 0 ? creditNotes.map((cn) => {
                                                    const nextPath =
                                                    {
                                                        pathname: `/companiesforcredit/${companyNameEncoded}/creditactions/creditnotes/view/${cn.id}`,
                                                        state: { creditNote: cn }
                                                    }

                                                    return (
                                                        <tr key={cn.id}>
                                                            <td>{cn.orderNumber}</td>
                                                            <td>{cn.invoice.currencyAbbreviation}</td>
                                                            <td>{(cn.formattedPostVatAmount || cn.formattedAmount)}</td>
                                                            <td>{cn.customerServiceAgentName}</td>
                                                            <td>{cn.createdDate ? toLocalTimeString(cn.createdDate) : "N/A"}</td>
                                                            <td>{this.getCreditNoteStatus((cn.postVatAmount || cn.amount), cn.amountRemaining)}</td>
                                                            <td className="text-center py-2">
                                                                <Button className="link-button" variant="secondary" size="sm"
                                                                    onClick={() => { this.props.history.push(nextPath); }}>View Details</Button>
                                                            </td>
                                                        </tr>);
                                                }) :
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <Image src='Images/search-result.png' className="mx-auto d-block" width="100px" />
                                                            <p className="text-center">
                                                                No Credit Notes available.
                                                            </p>
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                        <Pagination
                                            onNext={this.onNext}
                                            onPrevious={this.onPrevious}
                                            onPageSelect={this.onPageSelect}
                                            pageIndex={pageIndex}
                                            pageCount={pageCount} />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>}
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company
    }
}

const mapDispatchToProps = {
    getCreditNotes: companyActions.getCreditNotes
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCreditNotes)