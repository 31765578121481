import { GET, POST, PUT } from '../Consumer';
import Endpoints from '../components/common/Endpoints';

import * as actionType from '../actionTypes/userActionTypes';

export const userActions = {
    getUser,
    login,
    logout,
    resetPassword,
    getPasswordResetRequest,
    getFilteredCompanyUsers,
    changePassword,
    changePasswordForCustomerService
}

function getUser(id) {
    return async dispatch => {
        dispatch(request({ id }));

        try {
            const response = await GET(Endpoints.GET_USER_BY_ID + id)
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("User not found");
            }

            dispatch(success(result.data));
            return result.data;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(id) { return { type: actionType.USER_GET, id } }
    function success(user) { return { type: actionType.USER_GET_SUCCESS, user } }
    function failure(error) { return { type: actionType.USER_GET_FAILURE, error } }
}

function getFilteredCompanyUsers(id, filter, pageIndex) {
    return async dispatch => {
        dispatch(request({ id }));

        try {
            let url = new URL(Endpoints.USER.GET.FILTERED_USERS);

            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
            url.searchParams.append("companyId", id);
            url.searchParams.append("pageIndex", pageIndex);
            
            const response = await GET(url)
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Users not found");
            }

            dispatch(success(result.data));
            return result.data;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(id) { return { type: actionType.USER_GET_FILTERED_FOR_COMPANY, id } }
    function success(user) { return { type: actionType.USER_GET_FILTERED_FOR_COMPANY_SUCCESS, user } }
    function failure(error) { return { type: actionType.USER_GET_FILTERED_FOR_COMPANY_FAILURE, error } }
}

function login(username, password) {
    return async dispatch => {
        dispatch(request({ username }));

        try {
            const response = await POST(Endpoints.LOGIN, { email: username, password: password });
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Login failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(user) { return { type: actionType.USER_LOGIN, user } }
    function success(user) { return { type: actionType.USER_LOGIN_SUCCESS, user } }
    function failure(error) { return { type: actionType.USER_LOGIN_FAILURE, error } }
}

function logout() {
    return async dispatch => {
        dispatch(request());
    }

    function request() { return { type: actionType.USER_LOGOUT } }
}

function resetPassword(email) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await PUT(Endpoints.PASSWORD_RESET.PUT.RESET_PASSWORD + email);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Reset password failed");
            }

            dispatch(success());
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request() { return { type: actionType.USER_SEND_PASSWORD_RESET } }
    function success() { return { type: actionType.USER_SEND_PASSWORD_RESET_SUCCESS } }
    function failure(error) { return { type: actionType.USER_SEND_PASSWORD_RESET_FAILURE, error } }
}

function getPasswordResetRequest(passwordResetRequestID) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await GET(Endpoints.PASSWORD_RESET.GET.BY_ID + passwordResetRequestID)
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get reset password request failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request() { return { type: actionType.USER_GET_PASSWORD_RESET_REQUEST } }
    function success() { return { type: actionType.USER_GET_PASSWORD_RESET_REQUEST_SUCCESS } }
    function failure(error) { return { type: actionType.USER_GET_PASSWORD_RESET_REQUEST_FAILURE, error } }
}

function changePassword(form) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await PUT(Endpoints.PASSWORD_RESET.PUT.CHANGE_PASSWORD, form);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Change password failed");
            }

            dispatch(success());
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request() { return { type: actionType.USER_PASSWORD_CHANGE } }
    function success() { return { type: actionType.USER_PASSWORD_CHANGE_SUCCESS } }
    function failure(error) { return { type: actionType.USER_PASSWORD_CHANGE_FAILURE, error } }
}

function changePasswordForCustomerService(form) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await PUT(Endpoints.PASSWORD_RESET.PUT.CHANGE_PASSWORD_CUSTOMER_SERVICE, form);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Change password failed");
            }

            dispatch(success());
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request() { return { type: actionType.USER_PASSWORD_CHANGE } }
    function success() { return { type: actionType.USER_PASSWORD_CHANGE_SUCCESS } }
    function failure(error) { return { type: actionType.USER_PASSWORD_CHANGE_FAILURE, error } }
}