import { GET, PUT, POST } from '../Consumer';
import Endpoints from '../components/common/Endpoints';

import * as actionType from '../actionTypes/reportActionTypes';

export const reportActions = {
    getInvoiceSelazarBreakdown,
    getInvoiceRRSelazarBreakdown
}

function getInvoiceSelazarBreakdown(filter) {
    return async dispatch => {
        dispatch(request());

        try {
            let url = new URL(Endpoints.INVOICE_REPORTS.GET.INVOICE_BREAKDOWN);
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
            const response = await GET(url);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get invoice report with access status failed");
            }

            dispatch(success(result.data));
            return result;
        } catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }

        function request() { return { type: actionType.REPORT_INVOICEBREAKDOWN_GET_ALL } }
        function success(invoices) { return { type: actionType.REPORT_INVOICEBREAKDOWN_GET_ALL_SUCCESS, invoices } }
        function failure(error) { return { type: actionType.REPORT_INVOICEBREAKDOWN_GET_ALL_FAILURE, error } }
    }
}
function getInvoiceRRSelazarBreakdown(filter) {
    return async dispatch => {
        dispatch(request());

        try {
            let url = new URL(Endpoints.INVOICE_REPORTS.GET.INVOICE_BREAKDOWN);
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
            const response = await GET(url);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get invoice report with access status failed");
            }

            dispatch(success(result.data));
            return result;
        } catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }

        function request() { return { type: actionType.REPORT_INVOICEBREAKDOWN_GET_ALL } }
        function success(invoices) { return { type: actionType.REPORT_INVOICEBREAKDOWN_GET_ALL_SUCCESS, invoices } }
        function failure(error) { return { type: actionType.REPORT_INVOICEBREAKDOWN_GET_ALL_FAILURE, error } }
    }
}