import React from 'react';

const LoadingBar = () => {
    return (
        <div className="overlay" onClick={() => false}>
            <div className="loading">
                <div className="loading-bar" />
                <div className="loading-bar" />
                <div className="loading-bar" />
                <div className="loading-bar" />
            </div>
        </div>
    );
};

export default LoadingBar;